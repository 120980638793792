@import "variables";

.main-container.about-us h2{
  font-size: 72px;
  font-family: 'Bebas Neue',sans-serif;
  line-height: 63px;
  margin-bottom: 32px;
}

.content-container.about-us h1{
  font-size: 24px;
  color: #EA3553;
  margin-bottom: 20px;
}

.content-container.about-us section:first-of-type {
  display: flex;
}

.content-container.about-us section:last-of-type {
  display: flex;
  padding: 52px;
  flex-direction: column;
  position: relative;
}

.content-container.about-us section:last-of-type::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0;
  top: 0;
  background: #EA3553;
}

.content-container.about-us section:first-of-type img {
  width: 100%;
}

.content-container.about-us section {
  background: white;
  box-shadow: 0px 2px 12px rgba(46, 60, 84, .08);
  box-shadow: 0px 2px 12px rgba(0, 8, 16, .1);
  margin-bottom: 20px;
}

.content-container.about-us .aboutus-info-container {
  padding: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 84px;
}

.content-container.about-us p {
  font-size: 18px;
  line-height: 27px;
  opacity: .7;
}

.content-container.about-us .aboutus-image-container {
  min-width: 443px;
  height: 100%;
}

.content-container.about-us .btn {
  margin-top: 24px;
  width: 246px;
}

.content-container.about-us .content-container {
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 70px);
}

@media screen and (max-width:1200px) {
  .content-container.about-us .content-container {
    max-height: calc(100vh - 140px);
  }
}


@media screen and (max-width:960px) {
  .content-container.about-us section:first-of-type {
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .content-container.about-us .aboutus-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }

  .content-container.about-us section:first-of-type img{
    max-width: 100%;
    min-width: none;
  }

  .content-container.about-us .aboutus-info-container {
    padding: 48px 24px;
  }

  .content-container.about-us section:last-of-type {
    padding: 48px 24px;
  }
  .content-container.about-us section:last-of-type::before {
    display: none;
  }

}

.contact-us.content-container {
  max-height: calc(100vh - #{$header-search-height} - #{$footer-height});
  height: calc(100vh - #{$header-search-height} - #{$footer-height});
}

.contact_p p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: #2E3C54;
  margin-bottom: 6px;
}


.main_contact_us {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 2rem 1rem;
}

//.contact_btn.center_btn {
//  display: flex;
//  justify-content: center;
//  margin: 0 auto;
//}

.custom_btn_main.text-center.contact_btn {
  padding-top:10px;
  display: flex;
  justify-content: center;
}
//.custom_btn_main.contact_btn button {
//  padding: 16px 28px;
//  width: auto !important;
//  font-size: 14px;
//}







