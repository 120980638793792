.dashboard {
  .content-card {
    background: white;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(46, 60, 84, .1);
  }

  .content-card .header {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #172337;
    justify-content: space-between;
  }

  .content-card .header a {
    display: flex;
    height: 100%;
    padding: 0 20px;
    align-items: center;
  }

  .content-card .header-actions {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .content-card .header-actions .separator {
    height: 100%;
    width: 1px;
    opacity: .04;
  }

  .content-container {
    background: #F5F7FA;
    padding: 24px;
    // height: calc(100vh - 72px - 48px);
    // overflow: auto;
  }
  .content-card .separator {
    background: #2E3C54;
    opacity: .08;
  }
  .content-card .paragraph {
    background: #F5F7FA;
    padding: 1.2rem;
  }

  .content-card h2 {
    font-family: "Bebas neue", sans-serif;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 6px;
  }

  .content-card .body {
    width: 100%;
  }

  .content-card.add-new-card .body {
    padding: 20px;
  }

  .content-card.add-new-card {
    height: 567px;
    width: 100%;
    min-width: 475px;
    margin-right: 16px;
  }

  .content-card.add-new-card .upload-card {
    width: 100%;
    height: 422px;
    margin-top: 16px;
    background: #F6F9FF;
    border: 2px dashed rgba(46, 60, 84, .12);
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    transition: border-color .3s, background .3s;
  }

  .content-card.add-new-card .upload-card:hover {
    border-color: rgba(46, 60, 84, .20);
  }


  .content-card.add-new-card input {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }


  .content-card.add-new-card .choose-file {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;
    height: 160px;
    padding-top: 48px;
    padding-bottom: 20px;
    justify-content: space-between;
    background: url('../images/upload bg icon.svg');
    top: 67px;
    pointer-events: none;
  }


  .content-card.add-new-card .btn {
    position: absolute;
    bottom: 68px;
  }

  .content-card.recent-news {
    width: 100%;
    margin-right: 16px;
    overflow: hidden;
    flex: 1;
    /* height: 366px; */
  }

  .content-container-row2 {
    max-width: initial;
  }
  .content-card.recent-news .news-container {
    width: 100%;
    overflow-x: auto;
    /* padding: 1rem; */
    /* display: flex; */
    /* overflow: auto; */
    /* padding: 20px; */
    /* padding-left: 0; */
  }
  .content-card.recent-news .body {
    display: flex;
    overflow-y: hidden;
    padding-left: 12px;
    padding-top: 12px;
    /* padding: 1.25rem; */
    /* overflow: auto; */
    /* padding: 20px; */
    /* padding-left: 0; */
  }

  .content-card.recent-news .recent-news-item {
    color: #2E3C54;
    line-height: 140%;
    margin-left: 20px;
    max-width: 245px;
    min-width: 245px;
  }

  .content-card.recent-news .recent-news-item img{
    width: 100%;
  }
  .content-card.recent-news .recent-news-item-date {
    font-size: 12px;
    text-transform: uppercase;
    opacity: .7;
    letter-spacing: 1px;
    margin-top: 8px;
  }

  .content-card.recent-news .recent-news-item-title {
    margin-top: 4px;
  }
  .content-card.stats {
    min-width: 331px;
    /* height: 567px; */
  }

  .content-card.stats .stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    padding: 8px 20px;
    width: 100%;
  }

  .content-card.stats .stat-item p {
    opacity: .8;
    margin-bottom: 0;
  }

  .content-card.stats .stat-item.scans-left {
    flex-direction: column;
    height: 145px;
  }


  .content-card.stats .stat-item.scans-left .scans-this-month {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 58px;
    align-items: center;
  }

  .content-card.stats .stat-item.scans-left .bar {
    background: #EAECEE;
    width: 100%;
    height: 8px;
    margin-top: -22px;
    position: relative;
  }

  .content-card.stats .stat-item.scans-left .bar .loaded {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: #2E3C54;
  }

  .content-card.stats .stat-item.scans-left .btn {
    width: 100%;
    margin-bottom: 20px;
  }

  .content-card.batches-preview {
    min-width: 475px;
    width: 100%;
    /* height: 837px; */
    margin-right: 16px;
  }

  .content-card.batches-preview .cards-container {
    overflow: auto;
    /* height: 772px; */
    width: 100%;
  }

  .content-card.batches-inf {
    border: none;
  }

  .content-card.batches {
    min-width: 330px;
    /* height: 268px; */
  }

  .content-card.batches .batch-list{
    overflow-y: scroll;
    max-height: 682px;
  }

  .content-card.batches-preview .cards-container {
    .scard {
      display: flex;
      align-items: center;
      padding: 20px 20px;
      width: 100%;
      cursor: pointer;
      background: white;
      transition: background .3s;
      border-bottom: 1px solid rgba(46, 60, 84, .06);
    }

    .scard:hover {
      background: rgba(240, 244, 250, .7);
    }

    .scard .card-content {
      display: flex;
      position: relative;
    }

    .scard img {
      margin-right: 16px;
      width: 48px;
    }

    .scard .card-name {
      margin-top: 0px;
      margin-bottom: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 1.2em;
    }

    .scard .card-date-added {
      text-transform: uppercase;
      font-size: 12px;
      opacity: .7;
      letter-spacing: 1.2px;
    }

    .card-actions {
      font-size: 16px;
      opacity: 0;
      height: 20px;
      position: absolute;
      left: 64px;
      bottom: 4px;
      width: 180px;
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      transition: transform .3s, opacity .3s;
    }


    .card-actions a {
      transition: transform .3s;
      transform: translateY(100%);
    }

    .sscard:hover .card-actions {
      opacity: 1;
    }

    .scard:hover .card-actions a {
      transform: translateY(0);
    }

    .card-actions a:hover {
      text-decoration: underline;
    }
  }
  .select-batch {
    width: 250px;
  }
}
