.admin-dashboard {
  .main_contebt_admin {
    background: radial-gradient(28.91% 81.86% at 6.93% 9.5%, #FFFFFF 0%, rgba(254, 254, 255, 0.81) 100%);
    /*border: 1px solid #EAECEE;*/
    border-radius: 4px;
    padding: 24px;
  }

  .main_contebt_admin.active_statusadmin {
    margin-top: 1rem;
  }

  .chart_remove_paddinf {
    padding: 0;
  }

  .haed_stat_b h4 {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .admin_b_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #EAECEE;
    padding-bottom: 18px;
    font-style: normal;
    font-weight: 500;
    color: #2E3C54;
  }

  .admin_b_list p {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.272993px;
  }

  .admin_b_list h5 {
    font-size: 14px;
    line-height: 17px;
    text-align: right;
  }

  .b_all_btn button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FF1527;
    background: transparent;
    display: block;
    width: 100%;
    border: 1px solid #FF1527;
    box-sizing: border-box;
    height: 48px;
    transition: .3s;
  }

  .b_all_btn button:hover {
    background: #FF1527;
    color: #fff;
  }

  .graph_boxes {
    display: flex;
    margin-bottom: 20px;
  }

  .main_gbox {
    display: flex;
    border: 0.909977px solid #e2e2e8;
    box-sizing: border-box;
    border-radius: 9.09977px;
    padding: 12px 16px;
    margin-right: 20px;
    -webkit-transition: .3s;
    transition: .3s;
    flex-wrap: wrap;
  }

  .main_gbox.active, .main_gbox:hover {
    background: #2e3c54;
    h2, p{
      color: #fff;
    }
  }

  .main_gbox_l p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
    color: #2E3C54;
  }

  .main_gbox_l h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #2E3C54;
  }

  .main_gbox_r {
    padding-left: 40px;
    text-align: center;
  }
  .main_gbox_r p {
    font-style: normal;
    font-weight: 400;
    font-size: 11.0097px;
    line-height: 12px;
  }

  .main_gbox_r.pink_line p {
    color: #FB49C0;
  }

  .main_gbox_r.blue_line p {
    color: #4E96EC;
  }

  .main_gbox_r.yellow_line p {
    color: #F5A623;
  }

  .signup_state {
    background: radial-gradient(28.91% 81.86% at 6.93% 9.5%, #FFFFFF 0%, rgba(254, 254, 255, 0.81) 100%);
    border: 1px solid #EAECEE;
    border-radius: 4px;
    margin-top: 1rem;
    padding: 24px;

    .signup_state_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
    }

  }

  .main_bottom_page {
    margin-top: 1rem;

    .main_chart {
      background: radial-gradient(28.91% 81.86% at 6.93% 9.5%,#fff 0,rgba(254,254,255,.81) 100%);
      border: 0.930388px solid #eaecee;
      border-radius: 3.72155px;
      padding: 24px;
    }

    .main_gbox {
      width: 22%;
    }
  }

  .chart_date.chart_search {
    display: flex;
    align-items: center;
  }

  .chart_date select, .main_select_chart .nice_select {
    border: 1px solid #eaecee;
    box-sizing: border-box;
    background: #fff;
  }
  .chart_date select {
    height: 44px;
    font-size: 14px;
    padding: 10px;
  }

  .chart_date.chart_search button {
    background: #2e3c54;
    border-radius: 0;
    border: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    font-size: 26px;
    color: #fff;
  }

  .main_chart.red_main_charts .topcharts {
    margin-bottom: 2rem;
  }

  .main_users_active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 100px;
    box-shadow: 0 4px 6px 2px rgba(68, 81, 103, 0.1);
    border-radius: 10px;
    h4 {
      font-size: 5rem;
      font-weight: 700;
    }
    i {
      font-size: 7rem;
    }
  }

  .user_data_list h4 span {
    font-size: 30px;
    font-weight: 600;
    margin-left: -17px;
  }

  .bulk_table {
    border: 1px solid #EAECEE;
    box-sizing: border-box;
    border-radius: 5px;
    background: #F6F9FF;
    /*padding: 28px;*/
    margin-top: 1rem;
  }

  .table_head select {
    background: #FFFFFF;
    border: 0.696021px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 34.801px;
    width: 120px;
    padding: 6px 10px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 13.4403px;
    line-height: 13px;
    color: #4A4A4C;
  }

  .table_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px;
    background: #F6F9FF;
  }

  .table_head h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
  }

  .bulk_table.db_two_main .main_select {
    display: flex;
    width: 55%;
  }

  .custom_table_search_btn {
    display: flex;
    margin-left: 10px;
    width: 100%;
    input {
      background: #fff;
      border: 1px solid #fff;
      box-sizing: border-box;
      border-radius: 50px 0 0 50px;
      padding: 8px 20px;
      font-size: 13px;
      width: 100%;
      outline: none;
    }

    button {
      background: #2e3c54;
      border-radius: 0 50px 50px 0;
      display: flex;
      align-items: center;
      padding: 7px 10px;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 25px;
      text-transform: uppercase;
      color: #fff;
      outline: none;
    }

    i {
      font-size: 22px;
      margin-right: 4px;
    }
  }

  .action_icon i {
    background: #2e3c54;
    border-radius: 2px;
    padding: 3px 8px;
    font-size: 22px;
    color: #fff;
    cursor: pointer;
  }

  .bulk_table.db_two_main .table_footer {
    padding-top: 3rem;
  }


  .table_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 28px;
    background: #fff;
  }
  .entries_select {
    display: flex;
    align-items: center;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #4a4a4c;
      margin: 0;
    }
    select {
      background: #f6f9ff;
      border-radius: 20px;
      font-size: 13px;
      padding: 7px;
      width: 68px;
      margin: 0 10px;
      border: none;
    }
  }

  .table_main table tr td:first-child, .table_main table tr th:first-child {
    padding-left: 22px!important;
  }
  .table_main table tr td, .table_main table tr th {
    font-style: normal;
    white-space: nowrap;
  }

  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  .table td, .table th {
    border: none!important;
    vertical-align: middle!important;
    padding: 14px!important;
  }
  .table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  .table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  .table_main table tr td {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2e3b52;
    font-style: normal;
    white-space: nowrap;
  }
  .table_main table tr th {
    font-weight: 700;
    font-size: 12.48px;
    line-height: 14px;
    letter-spacing: .05em;
    color: #2e3c54;
    font-style: normal;
    white-space: nowrap;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff !important;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.usermanagement-detail {

  body {
    overflow: hidden;
  }

  .content-container {
    height: calc(100vh - 72px - 48px);
  }

  .profile-input {
    label {
      span {
        color: red
      }
    }
    input {
      width: 100%
    }
    margin-bottom: 30px;
  }

  .main_profile_sec {
    margin-bottom: 30px;
  }

  .admin_p_btn_nobg button:hover {
    background: #ea3553;
    color: #fff;
  }

  .admin_p_btn_nobg button {
    width: 100%;
    border: 1px solid #FF1527;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #FF1527;
    background: transparent;
    padding: 8px;
    transition: .3s;
    outline: none;
    white-space: nowrap;
  }

  .admin_p_fillbtn button {
    width: 100%;
    margin-top: 16px;
    background: #EA3553;
    border: 1px solid #ea3553;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 8px;
    transition: .3s;
    outline: none;
  }

  .main_bottom_page {
    margin-top: 1rem;
  }

  .admin_p_fillbtn button:hover {
    background: transparent;
    color: #FF1527;
  }

  .content-card.stats {
    min-width: 331px;
    height: 567px;
  }

  .content-card {
    background: #fff;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(46,60,84,.1);
    h2 {
      font-family: "Bebas neue",sans-serif;
      font-size: 28px;
      letter-spacing: 1px;
    }
  }
  .content-card .body {
    width: 100%;
  }

  .content-card .stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    padding: 0 20px;
    width: 100%;

    .separator {
      background: #2e3c54;
      opacity: .08;
    }
    p {
      opacity: .8;
    }
  }

  .content-card.stats {
    min-width: 331px;
    height: 567px;
  }

  .content-card .header {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #172337;
    justify-content: space-between;
  }

  .scans-this-month {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 58px;
    align-items: center;
  }
}