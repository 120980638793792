.app-integrations .integration {
  display: flex;
  justify-content: space-between;
  background: white;
  border: 1px solid rgba(46, 60, 84, .1);
  overflow: hidden;
}

.app-integrations h2 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 48px;
  color: #2E3C54;
  margin-bottom: 20px;
  letter-spacing: 0;
  font-weight: bold;
}

.app-integrations p {
  font-size: 16px;
  line-height: 25px;
  opacity: .8;
}

.integration-action img {
  margin-bottom: 10px;
}

.integration-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 12px rgba(46, 60, 84, 0.08), 0px 8px 16px rgba(46, 60, 84, 0.1);
  width: 120px;
  height: 120px;
  color: rgba(46, 60, 84, .8);
  font-weight: 500;
  font-size: 14px;
  margin-right: 20px;
  transition: box-shadow .5s;
}

.integration-info {
  padding: 52px;
}

.integration-actions {
  margin-top: 36px;
}

.integration-actions-container {
  display: flex;
  margin-top: 10px;
}

.integration-actions > p {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.82px;
  opacity: .8;
}

.integration.browser-extension .integration-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


.integration-action.inactive {
  cursor: auto;
  box-shadow: none;
  border: 1px solid rgba(46, 60, 84, 0.06);
}

.integration-action:hover {
  box-shadow: 0px 8px 20px rgba(46, 60, 84, 0.1), 0px 12px 24px rgba(46, 60, 84, 0.12);
}


.integration-action.inactive:hover {
  box-shadow: none;

}

.integration-image {
  overflow: hidden;
}

.integration-image img {
  height: 100%;
}

.integration.browser-extension .integration-image {
  min-width: 333px;
}

.integration.browser-extension .integration-image img {
  height: auto;
}

.list-on-ebay .integration-image img {
  height: auto;
}


.integration.desktop-app .integration-description:first-of-type {
  margin-bottom: 12px;
}

.integration.mobile-app .integration-description {
  max-width: 532px;
}

.integration.desktop-app .integration-info {
  max-width: 570px;
}


.integration.gle-control-panel .integration-image img {
  height: auto;
}


.integration.gle-control-panel .integration-image {
  min-width: 295px;
  height: 195px;
}

.integration.gle-control-panel .integration-info-features {
  margin-top: 32px;
}

.integration.gle-control-panel .integration-info-features span {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #EA3553;
}

.integration.gle-control-panel .integration-info-features h3 {
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  color: #2E3C54;
  margin-top: 8px;
}

.integration.gle-control-panel .integration-info-features p {
  margin-top: 16px;
}

.integration.gle-control-panel {
  position: relative;
}

.integration.gle-control-panel .integration-image {
  position: absolute;
  right: 52px;
  top: 24px;
}

.app-integrations .content-container {
  // overflow: auto;
  // height: calc(100vh - 72px - 48px)
}

.app-integrations {
  overflow: auto;
  height: 100%;
}

.app-integrations .integration {
  margin-bottom: 20px;
}

@media screen and (max-width: 1340px) and (min-width: 1201px) {
  .integration.browser-extension .integration-actions-container .integration-action {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .app-integrations .content-container {
    max-height: calc(100vh - 130px);
  }
}

@media screen and (max-width: 992px) {
  .integration-action {
    width: 100px;
    height: 100px;
  }
  .integration-action img {
    width: 40%;
  }
}

@media screen and (max-width: 916px) {
  .app-integrations .integration .integration-image {
    display: none;
  }

  .app-integrations .integration-info {
    max-width: 100%;
    width: 100%;
  }

  .app-integrations .integration.mobile-app .integration-description {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .app-integrations .integration-info {
    padding: 28px;
  }
  .app-integrations .integration-info h3 {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .app-integrations .integration-info {
    padding: 16px;
  }

  .app-integrations .integration-action {
    margin-right: 4px;
  }

  .app-integrations .integration-action:last-of-type {
    margin-right: 0px;
  }
}

.integration.desktop-app .integration-image img {
  max-width: 460px;
}