.sidebar {
  display: flex;
  z-index: 1000;
  flex-direction: column;
  width: 232px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #2E3C54;
  color: white;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 2px;
  font-size: 17px;
}

.sidebar .navigation h3 {
  font-weight: 700;
  font-family: "Bebas neue",sans-serif;
  display: block;
  font-size: 1.17em;
  margin-bottom: 0;
}

.sidebar .navigation .nav-item {
  border-top: 1px solid hsla(0,0%,100%,.04);
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  cursor: pointer;
  background: #2E3C54;
  transition: background .3s;
  position: relative;
}

.sidebar {
  .logo-container {
      width: 100%;
      height: 244px;
      display: flex;
      justify-content: center;
      align-items: center;
    img {
      width: 200px;
    }
  }
  .separator {
      background: white;
  }

  .user-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.25rem 1.5rem;
    border-top: 1px solid hsla(0,0%,100%,.04);
    .profile-wrapper {
      column-gap: .5rem;
      flex-grow: 1;
      display: flex;
      align-items: center;
      .user-image {
        width: 34px;
        height: 34px;
        border-radius: 17px;
        object-fit: cover;
        flex-shrink: 0;
      }
      .info {
        flex-grow: 1;
        font-family: 'Roboto', sans-serif;
        letter-spacing: normal;
        .user-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 0;
        }
        .profile-link {
          display: inline-block;
          color: #BDBDBD;
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
    .log-out-button {
      background: transparent;
      color: white;
      font-size: 1.25rem;
      transition: opacity .2s;
      &:hover {
        opacity: .7;
      }
    }
  }

  .navigation h3 {
    margin-left: 20px;
  }

  .nav-item:hover {
    background: #273347;
  }

  .nav-item.active {
    background: #273347;
  }

  .nav-item.active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 4px;
    height: 100%;
    background: #EA3553;
  }



  .nav-item.needs-upgrade::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: url('../images/crown icon.svg');
    right: 20px;
  }
}

