.export-setting-container {
  .tab-content {
    padding: 24px;
    background: #F5F7FA;
  }
  .content-container .tab-settings {
    .active {
      color: red;
    }
    li > button {
      color: rgba(23, 35, 55, 0.7);
      padding: 14px 24px  ;
    }
  }
  .ebay-template {
    background: white;
    padding: 30px;
    .template-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .edit-mode-ebay {
    .sub-link {
      span {
        cursor: pointer;
        margin-left: 8px;
        color: $color-main-red;
      }
      .flipped {
        transform: rotate(180deg);
      }
    }
  }
  .sub-link {
    svg {
      margin: 8px;
    }
  }
  .content-container {
    height: calc(100vh - 72px - 48px - 96px);
    padding: 0;
    overflow: auto;
  }
  .table_main {
    margin: 24px 0;
  }
  .table_main table tr {
    border-width: 1px;
  }

  .table_main table tr td, .table_main table tr th {
    font-style: normal;
    white-space: nowrap;
  }

  .table thead th {
    vertical-align: bottom;
    background: #F5F7FA;
  }
  .table td, .table th {
    border: none!important;
    vertical-align: middle!important;
  }
  .table th {
    padding: 0.75rem;
    vertical-align: top;
  }
  .table td {
    padding: 0.5rem 0.75rem;
    vertical-align: top;
  }

  .table_main table tr td {
    font-weight: 400;
  }

  .table_main table .name{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #2e3b52;
    white-space: nowrap;
  }

  .table_main table tr th {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: rgba(23, 35, 55, 0.6);
    white-space: nowrap;
  }
  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: white;
  }
  .template-operation {
    background: rgba(234, 53, 83, 0.04);
    color: #Ea3553;
    padding: 13px 12px;
    margin-right: 12px;
    svg {
      margin: 0
    }
  }
}


.ebay-template-info {
  .btn-toggler {
    background-color: transparent;
    margin-bottom: 1rem;
    font-weight: 500;
    color: #4879CD;
    display: inline-flex;
    align-items: center;
    column-gap: .75rem;
    img {
      transition: .2s;
      &.rotated {
        transform: rotate(180deg);
      }
    }
  }
  .param-section {
    margin-bottom: 24px;
    h3 {
      height: max-content;
      width: fit-content;
      font-size: 1.25rem;
      margin: 0;
      margin-bottom: 1.5rem;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 10px;
        background-color: #4879CD;
      }
    }

    .params-container {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 16px;
      width: fit-content;
    }
    .location {
      display: flex;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 16px;
      input, select {
        width: auto;
      }
    }
    .fw {
      margin-bottom: 16px;
      label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }
      textarea {
        min-width: 848px;
      }
    }
    .add-info {
      button {
        width: fit-content;
      }
    }
  }
  .span-optional {
    opacity: 0.6;
  }
  .offers-p {
    margin: 8px 0;
    font-size: 14px;
    width: 416px;
  }

  .Sample {
    input,
    button {
      font: inherit;
    }

    header {
      background-color: rgb(50, 54, 57);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      padding: 20px;
      color: white;

      h1 {
        font-size: inherit;
        margin: 0;
      }
    }

    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 10px 0;
      padding: 10px;

      & > * {
        & > * {
          margin: 10px;
        }
      }

      &__content {
        display: flex;
        max-width: 100%;
        flex-basis: 420px;
        flex-direction: column;
        flex-grow: 100;
        align-items: stretch;
      }
    }
  }
}

.custom-csv-template-info {
  background: white;
  padding: 30px;
  .param-section {
    margin-bottom: 24px;
    h3 {
      height: max-content;
      width: fit-content;
      border-bottom: solid #4879CD 3px;
      margin-bottom: 16px;
    }
  }
}