// body {
//   overflow: hidden;
// }

.batches {
  // .content-container {
  //   height: calc(100vh - 72px - 48px - 96px);
  // }

  .batches-container {
    display: flex;
    /* margin: -8px; */
    flex-wrap: wrap;
    gap: 1rem;
  }

  .batch {
    background: #FFFFFF;
    border: 1px solid rgba(46, 60, 84, 0.1);
    width: calc(100% / 3 - .67rem);
    height: 172px;
    max-height: 172px;
    display: flex;
    cursor: pointer;
    position: relative;
    transition: box-shadow .5s;
    color: rgba(46, 60, 84, 1);
    /* margin: 8px; */
  }

  .batch-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    padding-left: 0;
    .info-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: .5rem;
      column-gap: .5rem;
    }
  }

  .batch-description {
    display: flex;
    font-size: 14px;
    margin-bottom: 20px;
    opacity: .8;
  }

  .batch-category {
    margin-right: 12px;
  }

  .batch-dates {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 36px;
    border-top: 1px solid rgba(46, 60, 84, .1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }

  .batch-dates p {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .8px;
    margin-bottom: 4px;
    opacity: .7;
    margin-top: 3px;
  }

  //.batch-dates span {
  //  font-size: 11px;
  //  text-transform: uppercase;
  //  letter-spacing: .8px;
  //  margin-bottom: 4px;
  //  display: block;
  //}
  //
  //.batch-date-created {
  //  margin-right: 12px;
  //}



  .batch h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .batch-images {
    width: 92px;
    height: 136px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
  }

  .batch-images > div {
    position: absolute;
    display: flex;
    align-items: center;
    transition: transform .5s;
  }

  .batch-images > div img {
    width: 100%;
    height: 100%;
  }

  .batch-images > div > span {
    height: 100%;
    width: 100%;
  }

  .batch-images .top {
    width: 52px;
    height: 84px;
  }

  .batch-images .middle {
    width: 44px;
    transform: translateY(-22px);
  }

  .batch-images .bottom {
    width: 32px;
    transform: translateY(-28px);
  }


  .batch-status-container {
    margin-top: 20px;
    .open {
      color: #396DC5;
    }
    .processing {
      color: #bb0502;
      display: block;
      div {
        margin-left: 20px;
      }
    }
    .closed {
      color: #1F9C77;
    }
    .pending {
      color: #DA9D26;
    }
    img {
      margin-right: 4px;
    }
    p {
      margin-top: 2px;
    }
  }
  .batch-status {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .8px;
    display: flex;
    align-items: center;
    column-gap: 4px;
    /* position: absolute; */
    /* right: 20px; */
    /* top: 20px; */
  }

  .batch-status.open {
    color: #396DC5;
  }

  .batch-status.processing {
    color: #bb0502;
  }

  .batch-status.closed {
    color: #1F9C77;
  }
  .batch-status.pending {
    color: #DA9D26;
  }

  .batch-status img {
    position: relative;
    top: -1px;
  }

  .resubmit-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .batch:hover {
    box-shadow: 0px 4px 16px rgba(46, 60, 84, .16);
  }
  .batch:hover .batch-images .top {
    transform: translateY(2px);
  }
  .batch:hover .batch-images .middle {
    transform: translateY(-24px);
  }

  .batch:hover .batch-images .bottom {
    transform: translateY(-32px);
  }







  .btn.secondary.on-dark {
    color: #FF4564;
  }



  .btn.secondary.on-dark:hover {
    color: white;
  }

  .btn img {
    margin-right: 7px;
  }

  .input {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    background: #435066;
    transition: background .3s;
  }

  .input.dropdown {
    cursor: pointer;
  }


  .input img {
    margin-right: 12px;
  }

  .input:hover {
    background: #39465C;
  }

  .content-header-main .column {
    display: flex;
    align-items: center;
  }

  .content-header-main .column.right {
    min-width: 410px;
    justify-content: flex-end;
    width: 100%;
  }

  .content-header-main .column.right .input {
    margin-right: 16px;
  }

  .text-input {
    display: flex;
    flex-direction: column;
  }

  .text-input label {
    display: block;
    margin-bottom: 4px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .text-input.collection-name label {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: .5rem;
  }



  .more-options-menu {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 48px;
    transition: background .3s;
  }

  .more-options-menu > img {
    display: none;
  }

  .more-options-menu > img {
    opacity: .6;
    transition: opacity .3s;
  }

  .more-options-menu .menu-options {
    position: absolute;
    background: white;
    box-shadow: 0 8px 12px rgba(46, 60, 84, .22);
    right: 0;
    top: 100%;
    flex-direction: column;
    display: none;
  }

  .more-options-menu .menu-options .option {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(46, 60, 84, .12);
  }

  .more-options-menu:hover {
    opacity: 1;
  }

  .more-options-menu:hover > img{
    opacity: 1;
  }

  .has-menu.open {
    box-shadow: none;
    cursor: auto;
  }

  .has-menu:hover .more-options-menu > img{
    display: flex;
  }

  .has-menu .more-options-menu > img{
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  .has-menu {
    position: relative;
  }

  .has-menu .more-options-menu {
    z-index: 10;
    cursor: pointer;
  }



  .has-menu.menu-open::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(46,60,84,.2);
    display: flex;
    z-index: 1;
    top: 0;
    left: 0;
  }
  .has-menu.menu-open > .more-options-menu .menu-options{
    display: flex;
  }

  .has-menu.menu-open .more-options-menu{
    background: white;
  }

  .has-menu.menu-open .more-options-menu > img {
    display: flex;
    opacity: 1;
  }



  .input.dropdown {
    position: relative;
  }

  .dropdown-input-options {
    position: absolute;
    background: white;
    color:#172337;
    right: 0;
    top: 100%;
    box-shadow: 0px 12px 23px rgba(46, 60, 84, .4);
    display: none;
    z-index: 1000;
    pointer-events: none;
  }

  .dropdown-input-options select {
    width: 100%;
    height: 100%;
  }

  .dropdown-input-container {
    position: relative;
  }


  .dropdown-input-container.open .dropdownarrow {
    transform: rotate(180deg);
  }


  .batch-sort-select {
    background: #435066;
    color: white;
  }
  @media screen and (max-width: 800px) {
    .batch-sort-select {
      max-width: 60px;
    }
  }

  p {
    margin-bottom: 0;
  }

  .cards-num-info {
    display: flex;
    font-size: 14px;
    opacity: .7;
  }

  .cards-num-info span {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .8px;
    margin-bottom: 4px;
    display: block;
  }

  .batch-cards-matched {
    margin-right: 12px;
  }

}