.pagination {
    margin-bottom: 0!important;
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span,
.pagination>li>a,
.pagination>li>span {
    border-radius: 3.50058px;
    color: #8a919b!important;
    font-weight: 600;
    padding: 6px 12px;
    border: 1px solid #dee2e6;
    margin: 0 2px;
}

.pagination>li.disabled {
    opacity: .4;
}