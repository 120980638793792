
.login-bg {
  background-image: url('../../assets/images/loginBg.png');
  background-repeat: repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.login_page {
  .main-container.login {
    display: block;
    background: radial-gradient(50% 50% at 50% 50%, rgba(23, 35, 55, 0.94) 0%, #172337 100%);
    width: 100vw;
    height: 100vh;
    padding: 0;
    overflow: auto;
  }

  .main-container.login .sec-container {
    width: 100%;
    margin: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

  }

  .main-container.login .logo {
    display: block;
    width: auto;
    max-width: fit-content;
    height: fit-content;
    margin-top: 52px;
  }

  .main-container.login .logo > img{
    width: 200px;
  }

  .main-container.login .login-container {
    background: #F7F9FC;
    box-shadow: 0px 16px 64px rgba(11, 18, 29, 0.5);
    width: fit-content;
    margin-top: 48px;
    padding: 40px;
    padding-bottom: 32px;
    width: 100%;
    max-width: 484px;
    margin-bottom: 52px;
  }

  .main-container.login input{
    width: 100%;
  }

  .remember-me-forgot-password {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }


  .main-container.login form {
    margin-top: 40px;
    display: grid;
    row-gap: 20px;
  }

  .main-container.login h1 {
    font-family: 'Bebas neue', sans-serif;
    font-size: 44px;
  }

  .main-container.login .btn.primary {
    font-weight: bold;
  }

  .main-container.login .no-account-yet {
    margin: auto;
    width: fit-content;
    margin-top: 28px;
  }

  .main-container.login .no-account-yet span {
    opacity: .7;
  }

  .checkbox {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .checkbox input {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .checkbox .checkbox-overlay {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 2;
    pointer-events: none;
    background-color: white;
    border: 2px solid #D5D8DD;
    cursor: pointer;
    transition: background .3s;
  }


  .checkbox input:checked + div{
    background-color: #EA3553;
    background-size: contain;
    background-image: url('../../assets/images/checkedIcon.svg');
    border: none;
  }

  .main-container.login .btn.primary {
    position: relative;
  }

  .btn.busy {
    cursor: auto;
    pointer-events: none;
  }

  .main-container.login .btn.primary.busy::before {
    content: "Please wait...";
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    background: #F197A8;;
  }


  @media screen and (max-width:600px){
    .main-container.login .login-container {
      width: 92%;
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }

  }
}

.register_page {
  body {
    background-image: url('../../assets/images/loginBg.png');
    background-repeat: repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .main-container.register {
    display: block;
    background: radial-gradient(50% 50% at 50% 50%, rgba(23, 35, 55, 0.94) 0%, #172337 100%);
    width: 100vw;
    height: 100vh;
    padding: 0;
    overflow: auto;
  }

  .main-container.register .sec-container {
    width: 100%;
    margin: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .main-container.register .logo {
    display: block;
    width: auto;
    max-width: fit-content;
    height: fit-content;
    margin-top: 52px;
  }

  .main-container.register .logo img{
    width: 200px;
  }

  .main-container.register .register-container {
    background: #F7F9FC;
    box-shadow: 0 16px 64px rgba(11, 18, 29, 0.5);
    margin-top: 48px;
    padding: 40px 40px 32px;
    width: 100%;
    margin-bottom: 52px;
    .step-title {
      display: flex;
      justify-content: space-between;
      p {
        margin-right: 100px;
        font-weight: bold;
      }
    }
    .memberships {
      display: flex;
      justify-content: center;
    }
  }
  .first {
    max-width: 800px;
  }
  .second {
    max-width: 1440px;
  }

  .main-container.register input{
    width: 100%;
  }

  .remember-me-forgot-password {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }


  .main-container.register .form-container-main {
    margin-top: 40px;
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: .5fr .5fr;
  }

  .main-container.register h1 {
    font-family: 'Bebas neue', sans-serif;
    font-size: 44px;
  }

  .main-container.register .btn.primary {
    font-weight: bold;
  }

  .main-container.register .no-account-yet {
    margin: auto;
    width: fit-content;
    margin-top: 28px;
  }

  .main-container.register .no-account-yet span {
    opacity: .7;
  }

  .checkbox {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .checkbox input {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .checkbox .checkbox-overlay {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 2;
    pointer-events: none;
    background-color: white;
    border: 2px solid #D5D8DD;
    cursor: pointer;
    transition: background .3s;
  }


  .checkbox input:checked + div{
    background-color: #EA3553;
    background-size: contain;
    background-image: url('../../assets/images/checkedIcon.svg');
    border: none;
  }

  .main-container.register .remember-me {
    display: flex;
    align-items: center;
  }

  .main-container.register .remember-me .checkbox {
    margin-right: 8px;
  }

  .main-container.register .remember-me span{
    opacity: .7;
  }

  .main-container.register .btn.primary {
    position: relative;
  }

  .btn.busy {
    cursor: auto;
    pointer-events: none;
  }

  .main-container.register .btn.primary.busy::before {
    content: "Please wait...";
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    background: #F197A8;;
  }

  .main-container.register .input label {
    margin-bottom: 4px;
  }

  .select-input select {
    width: 100%;
  }

  .select-input .select-overlay {
    position: absolute;
    bottom: 0;
    height: calc(100% - 7px);
    min-height: auto;
  }

  .main-container.register .agreement {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .main-container.register .agreement .checkbox {
    margin-right: 8px;
  }

  .membership-item {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(46, 60, 84, .12);
    margin-right: 16px;
    min-width: 320px;
    position: relative;
  }

  @media screen and (max-width:600px){
    .main-container.register .register-container {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }

    .main-container.register .form-container-main {
      grid-template-columns: 1fr;
    }

  }

  p {
    margin-bottom: auto;
    margin-top: auto;
  }

  .trial-text {
    text-align: center;
    margin-bottom: 8px;
    font-size: 14px;
    opacity: .8;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .membership-info {
    background: rgba(72, 121, 205, .05);
    padding: 24px 24px 8px;
    margin-top: 12px;
  }

  .membership-info a {
    margin-left: 8px;
    font-size: 13px;
    color: red;
    cursor: pointer;
  }
  .membership-info > div {
    margin-bottom: 16px;
  }

  .membership-info > div p:first-of-type {
    opacity: .7;
    margin-bottom: 4px;
  }

  .membership-info h3 {
    font-weight: 500;
  }

  .input.text.pw{
    width: 100%;
  }

}


.reset-password {

  .main-container.reset-password {
    display: block;
    background: radial-gradient(50% 50% at 50% 50%, rgba(23, 35, 55, 0.94) 0%, #172337 100%);
    width: 100vw;
    height: 100vh;
    padding: 0;
    overflow: auto;
  }

  .main-container.reset-password .sec-container {
    width: 100%;
    margin: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .main-container.reset-password .logo {
    display: block;
    width: auto;
    max-width: fit-content;
    height: fit-content;
    margin-top: 52px;
  }

  .main-container.reset-password .reset-password-container {
    background: #F7F9FC;
    box-shadow: 0px 16px 64px rgba(11, 18, 29, 0.5);
    width: fit-content;
    margin-top: 48px;
    padding: 40px;
    padding-bottom: 32px;
    width: 92%;
    max-width: 500px;
    margin-bottom: 52px;
  }

  .main-container.reset-password input {
    width: 100%;
  }

  .remember-me-forgot-password {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }


  .main-container.reset-password form {
    margin-top: 40px;
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: 1fr;
  }

  .main-container.reset-password h1 {
    font-family: 'Bebas neue', sans-serif;
    font-size: 44px;
  }

  .main-container.reset-password .btn.primary {
    font-weight: bold;
  }

  .main-container.reset-password .no-account-yet {
    margin: auto;
    width: fit-content;
    margin-top: 28px;
  }

  .main-container.reset-password .no-account-yet span {
    opacity: .7;
  }

  .checkbox {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .checkbox input {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .checkbox .checkbox-overlay {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 2;
    pointer-events: none;
    background-color: white;
    border: 2px solid #D5D8DD;
    cursor: pointer;
    transition: background .3s;
  }


  .checkbox input:checked + div {
    background-color: #EA3553;
    background-size: contain;
    background-image: url('../../assets/images/checkedIcon.svg');
    border: none;
  }

  .main-container.reset-password .remember-me {
    display: flex;
    align-items: center;
  }

  .main-container.reset-password .remember-me .checkbox {
    margin-right: 8px;
  }

  .main-container.reset-password .remember-me span {
    opacity: .7;
  }

  .main-container.reset-password .btn.primary {
    position: relative;
  }

  .btn.busy {
    cursor: auto;
    pointer-events: none;
  }

  .main-container.reset-password .btn.primary.busy::before {
    content: "Please wait...";
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    background: #F197A8;;
  }

  .main-container.reset-password .input label {
    margin-bottom: 4px;
  }

  .select-input select {
    width: 100%;
  }

  .select-input .select-overlay {
    position: absolute;
    bottom: 0;
    height: calc(100% - 7px);
    min-height: auto;
  }

  .main-container.reset-password .agreement {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .main-container.reset-password .agreement .checkbox {
    margin-right: 8px;
  }

  .main-container.reset-password p {
    margin-top: 8px;
    line-height: 150%;
    opacity: .7;
  }


  @media screen and (max-width: 600px) {
    .main-container.reset-password .reset-password-container {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }

    .main-container.reset-password .form-container-main {
      grid-template-columns: 1fr;
    }

  }

  .main-container.reset-password .reset-password-container .input {
    display: block;
    height: auto;
    background: #F7F9FC;
    padding: 0px;
  }

  .main-container.reset-password .reset-password-container input {
    width: 100%;
    min-height: 48px;
    padding-left: 12px;
    border: 1px solid rgba(46, 60, 84, .2);
    outline: none;
    letter-spacing: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #2E3C54;
    transition: border .3s, color .3s;
  }

  .main-container.reset-password .logo > img{
    width: 200px;
  }

}