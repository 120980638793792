.profile_pic_sec {
  display: flex;
  align-items: center;
  margin-bottom: 1.3rem;
}

.pic_pro {
  position: relative;
}

.pic_pro:hover .hover_change {
  opacity: 1;
  transform: scale(1);
}

.hover_change {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e3c5480;
  border-radius: 50%;
  font-size: 34px;
  color: #fff;
  opacity: 0;
  transform: scale(0);
  transition: .3s;
  cursor: pointer;
}
