
header {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  background: #3A4C6B;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-search {
  display: flex;
  align-items: center;
  padding-left: 21px;
  width: 100%;
}

#header-search {
  display: block;
  height: 72px;
  width: 100%;
  margin-left: 12px;
  border: none;
  outline: none;
  background: transparent;
  color: white;
  font-size: 16px;
}

#header-search:active {
  border: none;
  outline: none;
}

#header-search::placeholder {
  color: white;
  opacity: .5;
  font-size: 16px;
}

header .notifications {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 72px;
  cursor: pointer;
  background: #3A4C6B;
  transition: background .3s;
}

header .notifications:hover {
  background: #2E405E;
}

header .profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  background: #3A4C6B;
  transition: background .3s;
}

header .profile p {
  width: 100%;
  white-space: nowrap;
  margin-bottom: 0;
}

header .profile:hover {
  background: #2E405E;
}

header .profile .user-image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 12px;
}

header .profile .user-fullname {
  margin-right: 12px;
}

header .profile .btn {
  padding-left: 20pt;
  padding-right: 20pt;
}

header .separator {
  width: 1px;
  height: 100%;
  background: white;
  opacity: .06;
}


header .profile {
  height: 100%;
  button {
    height: 100% !important;
    background: #3A4C6B !important;
    border-color: #3A4C6B !important;

    &:focus {
      box-shadow: none !important;
    }
  }
  button:hover, button:active{
    background: #2E3C54 !important;
    border-color: #2E3C54 !important;
  }
}