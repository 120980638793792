.card-details {

  .content-header-main h1 {
    white-space: nowrap;
  }

  .content-header-main .column.right {
    display: flex;
    align-items: center;
    column-gap: 1.25rem;
    .batches-card-pagination {
      text-align: center;
      .pagination-number {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: .25rem;
        strong {
          font-weight: 600;
        }
      }
      .pagination-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
        .action-button {
          background-color: #EA3553;
          font-size: 1.5rem;
          color: white;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: .75rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  } 

  body {
    overflow: hidden;
  }

  .content-container {
    padding-top: 0;
  }

  h1 {
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .card-main-image {
    max-width: 360px;
    padding: 24px;
    .next-prev-buttons {
      display: flex;
      justify-content: space-between;
      margin: 0 15px 15px 15px;
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .card-main-image .info-note {
    textarea {
      width: 100%;
      height: 180px;
    }
  }
  .card-main-image .user-submitted-image{
    //width: 280px;
    //height: 450px;
    text-align: center;
  }

  .card-main-image .user-submitted-image img{
    width: 100%;
    height: 330px;
    object-fit: scale-down;
  }

  .card-main-info-column .market-places {
    /* height: 168px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
  }

  .card-main-info-column .market-place {
    background: white;
    color: #2e3c54;
    border: 1px solid rgba(46,60,84,.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 4px;
    width: 100%;
    height: 48px;
    flex: 1;
    cursor: pointer;
    padding: 12px;
    margin-bottom: .5rem;
    transition: box-shadow .3s, border .3s, background .3s;
    .card-value-header {
      display: flex;
      align-items: center;
      column-gap: 4px;
      .card-icon {
        width: 1rem;
      }
      .card-heading {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        opacity: .8;
        position: relative;
        top: 1px;
      }
    }
  }

  .card-main-info-column .market-place:hover {
    box-shadow: 0px 6px 12px rgba(46,60,84,.2);
    border-color: transparent;
  }

  .card-main-info-column .market-place .card-value-header > img:first-of-type {
    /* min-width: 20px;
    max-width: 20px; */
    /* margin-right: 14px; */
  }

  .card-main-info-column .market-place p {
    width: 100%;
    /* margin-right: 14px; */
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .6px;
    text-transform: uppercase;
  }

  .card-main-info-column .market-place .open-in-new {
    display: flex;
    align-items: center;
  }

  .card-main-info-column .market-place img.market-place-logo {
    width: 40px;
    /* margin-right: 14px; */
  }

  .card-main-info .info{
    margin-bottom: 32px;
  }
  .card-main-info .info h4{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  .card-main-info .info p{
    font-family: 'Bebas Neue', sans-serif;
    opacity: .6;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .btn.primary.dark {
    background: #2e3c54;
  }
  .btn.primary.dark:hover {
    background: #222F45;
  }

  .card-main-info {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
  }

  .card-main-info-column {
  }
  .card-main-info-row1 {
    display: flex;
  }


  .card-main-info-col1, .card-main-info-col2 {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 312px;
  }

  .card-main-info-col2 {
    margin-left: 52px;
  }

  .card-main-info-row2 .add-to-remove-from-collection-button {
    display: flex;
  }

  .card-main-info-row2 .add-to-remove-from-collection-button .btn.primary.dark {
    margin-left: 12px;
  }

  .card-main-col2 {
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: flex-end;
  }
  .card-main-col2 .stock-image-container {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .card-main-col2 .stock-image{
    width: 100%;
    margin-bottom: 12px;
  }

  .card-main-info-row2 .subset-parallel-input-container {
    width: 100%;
    margin-bottom: 26px;
    margin-top: -4px;
  }

  .card-main-info-row2 .subset-parallel-input-container label {
    font-family: 'Bebas Neue', sans-serif;
    opacity: .6;
  }

  .card-main-info-row2 .subset-parallel-input-container select {
    width: 100%;
    margin-top: 6px;
  }

  .other-matched-cards-container {
    .other-matches-header {
      /* min-width: 200px; */
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin-bottom: 0;
      }
      button {
        background-color: transparent;
        font-weight: 600;
      }
    }
  }

  .other-matched-cards {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
    display: flex;
    align-items: center;
    /* column-gap: 6px; */
    .card-image {
      display: block;
      min-width: 30px;
      position: relative;
      transition: 300ms;
      .mini-image {
        width: 40px;
        border: 3px solid transparent;
        transition: .2s;
        &.selected {
          border-color: #EA3553;
        }
      }
      p {
        flex: none;
        font-size: 15px;
        font-weight: 600;
        opacity: .6;
        margin-left: 3px;
      }

      .card-gallery-view {
        position: absolute;
        top: 5rem;
        right: -8.5rem;
        background-color: white;
        box-shadow: 0px 2px 22px rgba(9, 38, 67, 0.29);
        padding: 1.25rem;
        border: 0;
        border-radius: 0;
        display: none;
        align-items: center;
        column-gap: .75rem;
        .gallery-image {
          width: 17.5rem
        }
      }

      &:hover {
        box-shadow: inset 0 0 10px 10px #4879CD;
        .card-gallery-view {
          display: flex;
        }
      }
    }
  }

  .other-matched-card {
    border: 1px solid rgba(46, 60, 84, .1);
    display: flex;
    align-items: center;
    .description {
      margin-left: 12px;
    }
    .card-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 145%;
      max-width: 400px;
      font-family: 'Roboto', serif;
      font-style: normal;
      overflow-wrap: anywhere;
    }
  }

  .content-container.card-details-main {
    overflow: auto;
  }

  .not-the-right-card-actions {
    display: flex;
    height: 45px;
    width: 100%;
    opacity: 0.7;
    align-items: center;
    border: 1px solid rgba(23, 35, 55, 0.1);
  }
  //
  //.not-the-right-card-actions .btn:first-of-type {
  //  margin-right: 12px;
  //}

  .card-main-col2 {
    width: 100%;
  }

  .card-main-image .stock-image {
    display: none;
  }

  .content-header-main-actions {
    display: grid;
    column-gap: 16px;
    grid-template-columns: auto auto auto;
  }

  .market-places {
    /* height: 216px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  .market-place.card-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    cursor: auto;
  }

  .market-place.card-value .card-value-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .market-place.card-value .card-value-header p{
    /* margin-left: 16px; */
  }

  .market-place.card-value .values{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
  }
  .market-place.card-value .value {
    display: flex;
    flex-direction: column;
  }

  .market-place.card-value .value p {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 14px;
    opacity: .7;
    margin-bottom: 3px;
    letter-spacing: .09em;
  }

  .market-place.card-value .value span {
    font-size: 14px;
  }

  .market-place.card-value:hover {
    box-shadow: none;
    border: 1px solid rgba(46, 60, 84, .08);
  }

  .market-place.card-value .more-info {
    position: relative;
  }

  .market-place.card-value .more-info p{
    display: none;
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
    position: absolute;
    width: 100%;
    background: white;
    z-index: 2000;
    line-height: 150%;
    padding: 12px;
    width: 280px;
    left: -182px;
    top: 26px;
    box-shadow: 0px 0px 20px rgba(46, 60, 84, .4);
  }

  .market-place.card-value .more-info p::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    left: 164px;
  }

  .market-place.card-value .more-info:hover p{
    display: flex;
  }

  .card-main-info .info input {
    min-height: 36px;
    max-height: 36px;
    font-weight: bold;
  }

  .card-main-info .info.editable {
    margin-bottom: 20px;
    .brand {
      min-height: 62px;
    }
  }
  .card-main-info .info.editable select{
    min-height: 43px;
    font-weight: bold;
    padding-left: 9px;
    width: 100%;
  }

  .card-main-info .info.editable textarea{
    max-height: 176px;
    font-weight: bold;
    padding: 9px;
    width: 100%;
  }

  .card-main-info .info.editable input{
    min-height: 43px;
    max-height: 43px;
    font-weight: bold;
    padding: 9px;
    width: 100%;
  }

  .card-main-info-row2 select {
    width: 100%;
  }

  .card-main-info-row2 textarea {
    width: 100%;
    min-height: 94px;
  }


  .card-images-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 168px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-images-container > div {
    width: 113px;
    //height: 194px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 24px;
  }

  .card-images-container img {
    width: 100%;
    margin-bottom: 5px;
    height: 194px;
    object-fit: contain;
  }

  .card-images-container span {
    margin-bottom: 5px;
    display: block;
    opacity: .7;
  }

  .card-match-status {
    position: sticky;
    top: 0;
    z-index: 100;
    margin: 0 -24px 24px -24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 60px;
    padding: 12px 24px;
    background: white;
    box-shadow: 0 2px 10px rgba(9, 38, 67, 0.12);
    .box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.matched-to {
        .other-matched-card {
          padding: 8px 12px;
          column-gap: 12px;
          .description {
            margin-left: 0;
            .card-title {
              font-size: 14px;
              font-weight: 600;
            }
          }
          img {
            width: 30px;
          }
        }
      }
    }
    h2 {
      font-family: 'Bebas neue', sans-serif;
      font-size: 1rem;
      opacity: .6;
      letter-spacing: 0.09em;
    }
  }

  .card-image {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .card-added-date {
    margin-top: 12px;
    font-size: 14px;
    opacity: .6;
  }

  .other-matched-card {
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
  }

  .batch-cards-list-container {
    display: flex;
    position: sticky;
    bottom: 0;
    z-index: 100;
    background: white;
    box-shadow: 0 2px 10px rgba(9, 38, 67, 0.12);
    margin: 24px -24px 0 -24px;
    align-items: center;
    padding: 12px 0;
    .batch-cards-list {
      width: 100%;
      padding: 0 18px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 12px;
    }
    .other-matched-card.nav-card {
      cursor: pointer;
      background-color: #F5F7FA;
    }
    .other-matched-card.nav-card:hover {
      box-shadow: 0 8px 12px rgba(46, 60, 84, .12);
    }

    .btn-prev {
      margin-left: 24px;
    }
    .btn-next {
      margin-left: auto;
      margin-right: 24px;
    }
  }

  .card-main {
    //position: relative;
    background: white;
    //  width: 100%;
    //  margin: auto;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 24px;
  }


  .inventory-card-setting {
    h2 {
      margin-bottom: 20px;
      font-size: 1.5rem;
      font-weight: bold;
      font-family: 'Bebas Neue', sans-serif;
      opacity: .6;
    }
    background: white;
    width: 100%;
    margin-top: 16px;
    padding: 24px;
    .inventory-settings {
      .inventory-inputs {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-row-gap: 24px;
        .row1 {
          grid-column-start: 1;
          grid-column-end: 6;
          grid-row-start:1;
          grid-row-end:2;
        }
        .inventory-field {
          label { margin-bottom: 8px;}
          input { width: 196px;}
        }
      }
      h3.underline {
        position: relative;
        margin-top: 24px;
        margin-bottom: 24px;
        width: fit-content;
        border-bottom: #4879CD 3px solid;
      }
    }
  }

  .other-settings-inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
  }



  .other-settings-inputs > div {
    margin: 12px;
  }

  .fw input, .fw select {
    margin-top: 6px;
    width: 416px;
  }

  .hw input, .hw select {
    /* margin-top: 6px; */
    width: 196px;
  }

  .qw input, .qw select {
    margin-top: 6px;
    width: 98px;
  }

  .no-preview-image {
    height: 330px;
    border: 1px dashed red ;
    span {
      margin: auto
    }
    .no-image {
      display: flex;
      height: calc(100% - 20px);
    }
    .no-image-upload {
      margin-bottom: 0;
      height: auto;
      display: flex;
      cursor: pointer;
      justify-content: center;
    }
  }
  .card-item-image-preview {
    margin: 10px auto;
    display: flex;
    background: #E1E5EB;
    border: 1px solid rgba(46, 60, 84, .1);
    border-radius: 4px;
    justify-content: flex-start;
    height: 40px;
    width: 140px;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    .tab-front-back-switch {
      width: 100%;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }
    .active {
      background: white;
    }
  }
  //.card-item-image-preview > span {
  //  color: red;
  //  cursor: pointer;
  //}
  //.card-item-image-preview .back {
  //  margin-left: 50px;
  //}

  //.card-item-image-preview .active {
  //  text-decoration: underline;
  //}

  @media screen and (max-width: 2400px) {
    .other-matched-cards {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  @media screen and (max-width: 2048px) {
    .other-matched-cards {
      //grid-template-columns: auto auto auto auto auto auto auto auto auto;

    }
  }

  @media screen and (max-width: 1600px) {
    .other-matched-cards {
      grid-template-columns: auto auto auto auto;
    }
  }


  @media screen and (max-width: 1280px) {
    .card-main-image .user-submitted-image img {
      display: block;
    }

    .card-main-col2 {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    //.card-main-image .user-submitted-image {
    //  display: flex;
    //  justify-content: space-between;
    //}

    .card-main-image .user-submitted-image img {
      width: 100%;
    }

    .card-main-image .user-submitted-image .stock-image {
      display: none;
    }

    .card-main-col2 {
      display: flex;
    }
  }

  @media screen and (max-width: 1000px) {
    //.card-main-image .user-submitted-image {
    //  display: flex;
    //  justify-content: space-between;
    //}

    .card-main-image .user-submitted-image img {
      display: block;
    }
    .card-main-image .user-submitted-image .stock-image {
      display: block;
    }

    .card-main-col2 {
      display: none;
    }
  }


  @media screen and (max-width: 820px) {
    .other-matched-cards {
      grid-template-columns: auto;
    }

    .card-main-info .info h4 {
      font-size: 15px;
    }

    .card-main {
      flex-direction: column;
    }

    .card-main .btn.primary {
      width: 100%;
    }

    .card-main .card-main-image {
      //display: flex;
      width: 100%;
      max-width: 100%;
      align-items: flex-end;
    }

    .card-main .card-main-info-column .market-places {
      margin-left: 24px;
    }

    .content-header-main-actions {
      display: none;
    }
  }

  @media screen and (max-width: 538px) {
    .card-main .card-main-image {
      flex-direction: column;
      align-items: center;
    }
    .card-main .card-main-info-column .market-places {
      margin-left: 0px;
    }

    .card-main .add-to-remove-from-collection-button {
      flex-direction: column;
    }

    .card-main .add-to-remove-from-collection-button .btn.primary {
      flex-direction: column;
      text-align: center;
      padding: 16px;
    }

    .card-main .add-to-remove-from-collection-button .btn.primary b{
      white-space: normal;
      margin-top: 12px;
    }

    .card-main .btn.primary.dark:last-of-type {
      margin-left: 0;
      margin-top: 8px;
    }

    .not-the-right-card-actions {
      flex-direction: column;
      margin-bottom: 24px;
    }

    .not-the-right-card-actions .btn.secondary:first-of-type {
      margin-right: 0;
      margin-bottom: 8px;
    }

    .matches-found-notify {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 359px) {
    .card-main-image {
      width: 100%;
      min-width: auto;
      padding: 0;
    }
    //.card-main-image .user-submitted-image {
    //  width: 100%;
    //  max-width: 100%;
    //}

    .card-main-info-column .market-places {
      width: 100%;
      padding: 0 12px;
    }

    .card-main-info-column .market-place {
      width: 100%;
      max-width: 100%;
    }

    .card-main-info-col2 {
      margin-left: 12px;
    }
  }

  .breadcrumbs {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: 13px;
  }

  .column.left.vert {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .breadcrumbs img {
    margin: 0 8px;
  }

  .breadcrumbs .current-page {
    opacity: .7;
    //max-width: 120px;
    //overflow: hidden;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}
