@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import "variables";
@import "avatar";
@import "navbar";
@import "sidebar";
@import "dashboard";
@import "scanner";
@import "download";
@import "batches";
@import "batchdetail";
@import "settings";
@import "popups";
@import "login";
@import "carddetail";
@import "about";
@import "admin";
@import "pagination";
@import "payment";
@import "exportsettings";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

#root {
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

body {
    font-family: 'Roboto', sans-serif;
    color: #2E3C54;
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    //margin-top: inherit;
    //margin-bottom: inherit;
    //font-weight: inherit;
    line-height: inherit;
}

a {
    text-decoration: none;
    color: #EA3553;
    transition: opacity .3s;
}

.btn.primary.round {
    min-width: 48px;
    min-height: 48px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-shadow: 0px 4px 24px rgba(234, 53, 83, .5);
}

.btn.primary.round img {
    margin: 0;
}

.btn.primary.round.floating {
    position: fixed;
    bottom: 84px;
    right: 12px;
    display: none;
}

button {
    border: none;
}

input[type='submit'] {
    border: none;
}

input[type='number'],
input[type='text'],
input[type='password'],
input[type='checkbox'],
input,
textarea,
select {
    min-height: 48px;
    padding-left: 12px;
    border: 1px solid rgba(46, 60, 84, .2);
    outline: none;
    letter-spacing: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #2E3C54;
    transition: border .3s, color .3s;
}

select {
    /* border-right: 8px solid transparent !important; */
    /* position: relative; */
    -moz-appearance: none;
-webkit-appearance: none;
appearance: none;

background-image: url('../images/expand-more.svg');
background-repeat: no-repeat;
background-position: right .25rem top 50%;
/* background-size: .65em auto; */
    /* &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 19px;
        height: 100%;
        background: black;
    } */
    /* &::exp */
}

input[type='text']:hover,
input[type='password']:hover,
input[type='checkbox']:hover,
textarea:hover,
select:hover {
    border: 1px solid rgba(46, 60, 84, .5);
}

input[type='text']:focus,
input[type='checkbox']:focus,
textarea:focus,
select:focus {
    border: 1px solid rgba(46, 60, 84, .5);
}



textarea {
    min-height: 200px;
    padding-top: 12px;
    resize: none;
    line-height: 24px;
}

.search-m {
    display: none;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #2E3C54;
    opacity: .5;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #2E3C54;
    opacity: .5;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #2E3C54;
    opacity: .5;
}

select {
    cursor: pointer;
    position: relative;
    padding-right: 8px;
}

@-moz-document url-prefix() {
    select {
        padding-top: 13px;
    }
}

.select-input {
    position: relative;
}

.select-overlay {
    position: absolute;
    width: 20px;
    height: 44px;
    top: 2px;
    right: 1px;
    background-color: white;
    z-index: 1;
    pointer-events: none;
}

.select-overlay::after {
    content: "";
    width: 12px;
    height: 8px;
    position: absolute;
    z-index: 2;
    top: 18px;
    right: 12px;
    background: url('../images/carretIconBlack.svg');
    opacity: .7;
}

label {
    cursor: pointer;
    display: block;
    font-weight: 500;
    span {
        font-weight: 400;
    }
}

option {
    display: block;
    height: 48px;
}

.text-input-range {
    display: flex;
}

.text-input-range img {
    margin: 0 4px;
}

.check-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}

.check-input label {
    display: block;
    margin-left: 8px;
    margin-top: 2px;
}

.check-input input {
    display: block;
    max-width: 24px;
    margin-right: 8px;
    height: 24px;
    margin: 0;
}

.separator-y {
    width: 1px;
    background: #2E3C54;
    opacity: .1;
}

label {
    display: block;
}

a:hover {
    opacity: 1;
}

.link {
    color: #EA3553;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    transition: background .3s, color .3s;
}

.actions-m {
    display: none;
}

.stat-item.m {
    display: none;
}



.btn.primary {
    color: white;
    background: #EA3553;
    white-space: nowrap;
}

.btn.primary:hover {
    background: #D1304B;
}

.btn.secondary {
    border: 1px solid rgba(350, 77, 92, .4);
    color: #EA3553;
    white-space: nowrap;
}

.btn.secondary:hover {
    color: white;
    background: #EA3553
}

.btn svg {
    margin-right: 8px;
}

.btn.secondary.on-dark svg {
    fill: #FF5470;
    transition: fill .3s;
}

.btn.secondary svg {
    fill: #EA3553;
    transition: fill .3s;
}

.btn.secondary:hover svg {
    fill: white;
}

.main-container {
    padding-left: 232px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > :first-child {
        flex-grow: 1;
    }
}

.separator {
    height: 1px;
    opacity: .04;
}


.navigation {}

.notifications .container {
    position: relative;
}

.notifications img {
    display: block;
}

.notifications .notif-num {
    position: absolute;
    background: #EA3553;
    min-width: 22px;
    min-height: 22px;
    padding: 4px;
    border-radius: 100%;
    font-size: 13px;
    top: -12px;
    right: -10px;
    color: white;
}

.content-container {
    background: #F5F7FA;
    padding: 24px;
    // height: calc(100vh - 72px - 48px);
    // overflow: auto;
}

.back-btn {
    display: none;
    color: white;
    opacity: .9;
}

.back-btn img {
    margin-right: 8px;
}

.content-container-row1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
}

.content-container-row2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: calc(100vw - 641px);
}

.content-container-row3 {
    width: 100%;
    height: 210px;
}
.content-container-row3 .advertisement {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808693;
    background-color: #E8EAEE;
}

.header-actions .action {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    transition: background .3s;
}

.header-actions .action:hover {
    background: rgba(240, 244, 250, .7);
}

.header-actions .action.select img {
    margin-left: 8px;
    opacity: .8
}


.header-actions .action.select {
    opacity: .8;
}

.header-actions .action.select:hover {
    opacity: 1;
}




.content-card.collections {
    margin-bottom: 16px;
}

.content-card.collections,
.content-card.checklists {
    min-width: 331px;
    width: 331px;
    height: 366px;
}

.content-card .collection,
.checklist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background: white;
    transition: background .3s;
    cursor: pointer;
}

.content-card .collection:hover,
.checklist:hover {
    background: rgba(240, 244, 250, .7);
}


footer {
    // height: 48px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .75rem 1.5rem;
    // padding-left: 1.5rem;
    // padding-right: 1.5rem;
    width: 100%;
    box-shadow: 0px -4px 16px rgba(46, 60, 84, 0.06);
    /* border-top: 1px solid rgba(46, 60, 84, .1); */
    position: relative;
}

footer p {
    opacity: .9;
    margin: 0;
}

.footer-links {
    display: flex;
    column-gap: 28px;
    /* width: 576px; */
    /* justify-content: space-between; */
    /* margin-right: 150px; */
}

.footer-links a {
    opacity: .9;
    color: #2E3C54;
}


.dropdown.sort {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    min-height: 48px;
    max-height: 48px;
    background: white;
    border: 1px solid rgba(46, 60, 84, .1);
    transition: box-shadow .3s;
    padding-left: 14px;
}

.dropdown.sort:hover {
    box-shadow: 0 8px 12px rgba(46, 60, 84, .12);
}

.dropdown.sort img {
    margin-right: 10px;
}

.dropdown.sort.on-mobile {
    display: none;
}



@media screen and (max-width: 1200px) {
    .sidebar {
        flex-direction: row;
        font-family: "Bebas Neue", sans-serif;
        letter-spacing: 2px;
        .logo-container img {
            width: 60px;
        }
    }

    header {
        padding-left: 90px;
        z-index: 900;
    }

    .header-search img {
        margin-right: -10px;
    }

    .separator {
        height: 1px;
        opacity: .04;
    }

    .sidebar .separator {
        background: white;
    }

    .navigation {}

    .nav-item {
        height: 100%;
        align-items: center;
        padding-left: 0;
        position: relative;
    }



    .nav-item:hover {
        //background: #273347;
    }

    .nav-item.active {
        //background: #273347;
    }

    .nav-item.active::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 4px;
        background: #EA3553;
    }

    .nav-item.needs-upgrade::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background: url('../images/crown icon.svg');
        right: 20px;
    }


    .sidebar {
        width: 100%;
        height: 72px;
        top: calc(100% - 72px);
        bottom: 0;
    }

    .logo-container {
        position: fixed;
        top: -86px;
        left: 24px;
        width: 60px;
        z-index: 1000;
    }

    .navigation {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        height: 100%;
        max-height: 72px;
    }

    .nav-item {
        font-size: 12px;
        width: 100%;
        justify-content: center;
    }

    .nav-item h3 {
        margin-left: 8px;
        margin-top: 1px;
    }

    .main-container {
        padding-left: 0;
    }

    footer {
        flex-direction: column;
        justify-content: center;
        height: auto;
    }

    footer p {
        margin-bottom: 8px;
        opacity: .7;
        text-align: center;
    }

    .footer-links{
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }



    .footer-links a {
        margin: 8px;
    }

    footer {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 65px;
    }



    .content-container-row2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: calc(100vw - 410px);
    }


}

@media screen and (max-width: 950px) {
    .nav-item {
        flex-direction: column;
    }

    .nav-item h3 {
        margin: 0;
        margin-top: 8px;
    }
}

@media screen and (max-width: 890px) {
    .content-container-row1, .content-container-row2 {
        flex-direction: column;
        max-width: 100%;
    }

    .content-container-row1 .content-card.stats {
        margin-top: 16px;
    }

    .content-container-row2 .content-card.recent-news {
        width: 100%;
    }

    .content-container-row2 .content-card.batches {
        width: 100%;
        max-width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
    }



    .content-card.batches-preview .body {

    }

    .content-card.batches-preview .cards-container {
        overflow: auto;
        height: 502px;
        width: 100%;
    }

    .content-card.batches-preview {
        max-height: 443px;
    }
    .content-card.batches-preview .body{
        max-height: 376px;
    }
    .content-card.batches-preview .cards-container {
        max-height: 376px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
.card {
    display: flex;
    align-items: center;
    padding: 20px 20px;
    height: 120px;
    max-height: 120px;
    width: 280px;
    max-width: 280px;
    cursor: pointer;
    background: white;
    transition: background .3s;
    border-bottom: 1px solid rgba(46, 60, 84, .06);
}

.card:hover {
    background: rgba(240, 244, 250, .7);
}

.card .card-content {
    display: flex;
    position: relative;
}

.card img {
    margin-right: 16px;
    width: 48px;
}

.card .card-name {
    margin-top: 0px;
    margin-bottom: 8px;
    text-overflow:ellipsis;
    overflow: hidden;
    height:58px;
    font-size: 14px;
    line-height: 140%;
}

.card .card-date-added {
    text-transform: uppercase;
    font-size: 11px;
    opacity: .7;
    letter-spacing: 1.2px;
}

.card-actions {
    font-size: 16px;
    opacity: 0;
    height: 20px;
    position: absolute;
    left: 64px;
    bottom: 4px;
    width: 180px;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    transition: transform .3s, opacity .3s;
}


.card-actions a {
    transition: transform .3s;
    transform: translateY(100%);
}

.card:hover .card-actions {
    opacity: 1;
}

.card:hover .card-actions a {
    transform: translateY(0);
}

.card-actions a:hover {
    text-decoration: underline;
}
}

@media screen and (max-width: 850px) {
    .nav-item h3 {
        font-size: 12px;
    }

    .logo-container {
        display: none;
    }

    header {
        padding: 0;
    }
}


@media screen and (max-width: 750px) {
    .nav-item h3 {
        font-size: 10px;
    }

    .nav-item img {
        width: 24px;
    }

    .content-card.batches-preview {
        min-width: auto;
    }
}



@media screen and (max-width: 600px) {

    #header-search {
        background: white;
        height: 48px;
        padding-left: 0;
        margin-left: 16px;
    }

    header {
        padding-left: 24px;
    }

    .header-search {
        background: white;
    }

    #header-search::placeholder {
        color: #2E3C54;
    }

    .header-search img:first-child {
        display: none;
    }

    .header-search .search-m {
        display: block;
    }

    .header-search {
        padding-left: 16px;
    }

    .nav-item.settings {
        display: none;
    }

    .nav-item.integrations span {
        display: none;
    }

    .profile .user-fullname {
        display: none;
    }

    .profile img.user-image {
        margin-right: 0;
    }

    .profile .chevron {
        display: none;
    }

    .profile {
        position: relative;
    }



    .main-container .content-header-main {
        padding: 0 16px;
    }

    header {
        padding-left: 16px;
    }

    .content-container {
        padding: 16px;
    }

    .dropdown.sort.on-mobile {
        display: flex;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 420px) {
    header {
        padding-left: 16px;
    }

    header .separator {
        display: none;
    }

    header .profile {
        padding: 0 16px;
    }

    header .notifications {
        min-width: 0px;
        padding: 0 16px;
    }

    .header-search {
        padding-left: 12px;
    }

    .profile::before {
        right: 8px;
    }
}


@media screen and (max-width: 375px) {
    .sidebar .nav-item h3 {
        font-size: 8px;
    }
}


@media screen and (max-width: 350px) {
    #header-search::placeholder {
        font-size: 14px;
        opacity: .6;
    }

    .header-search img {
        width: 20px;
    }

    .header-search {
        padding-left: 8px;
    }

    .column.right .btn.primary {
        display: none;
    }

    .btn.primary.round.floating {
        display: flex;
    }
}

.content-header-main {
    background: #172337;
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 96px;
    padding: 0 24px;
    position: sticky;
    top: 0;
    z-index: 100;
}

.content-header-main h1 {
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
}

body {
    line-height: inherit;
}

.custom-control {
    display: flex;
    justify-content: center;
    width: fit-content;
}
.custom-control-label:before {
    background-color:white;
    top: 0;
}
.custom-control-label:before, .custom-control-label:after{
    width: 20px;
    height: 20px;
    top: 0
}
.custom-control-label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: fit-content;
}
.custom-checkbox input {
    width: fit-content;
}
.custom-radio input {
    width: fit-content;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
    background-color:$color-main-red;
    border: unset;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before{
    background-color:$color-main-red;
    border: unset;
}

.flipped {
    transform: rotate(180deg);
}