.settings {

  .content-header-main h1 {
    white-space: nowrap;
  }

  .tabs-input {
    margin-right: 12px;
  }

  body {
    //overflow: hidden;
  }

  .side-nav {
    width: 196px;
    flex: none;
    background: white;
    height: 100%;
    position: sticky;
    top: 0;
    padding: 24px 0;
    .side-nav-route {
      padding: 12px 16px;
      a {
        color: unset;
      }
    }
    .active {
      font-weight: bold;
    }
    .side-nav-route.selected {
      border-left: 4px solid #EA3553;
      background: #F4F4F4;
    }

    .nav {
      display: flex;
      align-items: center;
      position: -webkit-sticky;
      position: sticky;
      top: -5px;
      z-index: 2;
      width: max-content;
      height: auto;
      padding: 0 1rem;
      box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.11);
    }

    .nav__container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      position: relative;
    }

    .nav__container__actions {
      display: inline-flex;
      flex-flow: row nowrap;
      padding-left: 50px;
      flex: 1;
    }

    .active {
      font-weight: bold;
    }

    ul {
      gap: 1rem;
      font-size: 0.9rem;
      list-style: none;
      padding: 0;
    }

    li {
      cursor: pointer;
    }

    li:hover {
      text-decoration: underline;
    }
  }
  .content-container {
    height: calc(100vh - 72px - 48px - 96px);
    display: flex;
    padding: 0;
  }
  .nav-content {
    padding: 24px;
    flex-grow: 1;
    overflow: auto;
  }

  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: bold;
    text-transform: capitalize;
  }

  h3 {
    font-size: 1.17em;
    margin: 0;
    font-weight: bold;
  }

  .card-main-col1 {
    width: 100%;
    max-width: 324px;
    padding: 24px;
  }

  .card-main-col1 .user-submitted-image{
    max-width: 280px;
    width: 100%;
  }

  .card-main-col1 .user-submitted-image img{
    width: 100%;
  }

  .card-main-info-column .market-places {
    height: 168px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
  }

  .card-main-info-column .market-place {
    background: white;
    color: #2e3c54;
    border: 1px solid rgba(46,60,84,.1);
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    cursor: pointer;
    padding: 14px;
    transition: box-shadow .3s, border .3s, background .3s;
  }

  .card-main-info-column .market-place:hover {
    box-shadow: 0px 6px 12px rgba(46,60,84,.2);
    border-color: transparent;
  }

  .card-main-info-column .market-place > img:first-of-type {
    min-width: 20px;
    max-width: 20px;
    margin-right: 14px;
  }

  .card-main-info-column .market-place p {
    width: 100%;
    margin-right: 14px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .6px;
    text-transform: uppercase;
  }

  .card-main-info-column .market-place .open-in-new {
    display: flex;
    align-items: center;
  }

  .card-main-info-column .market-place img.market-place-logo {
    width: 48px;
    margin-right: 14px;
  }

  .card-main-info .info{
    margin-bottom: 32px;
  }
  .card-main-info .info h4{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  .card-main-info .info p{
    font-family: 'Bebas Neue', sans-serif;
    opacity: .6;
    font-weight: 400;
    margin-bottom: 4px;
  }

  .btn.primary.dark {
    background: #2e3c54;
  }
  .btn.primary.dark:hover {
    background: #222F45;
  }

  .card-main-info {
    padding: 24px;
  }

  .card-main-info-row1 {
    display: flex;
  }


  .card-main-info-col1, .card-main-info-col2 {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 312px;
  }

  .card-main-info-col2 {
    margin-left: 32px;
  }

  .content-container.card-details-main {
    overflow: auto;
  }

  .not-the-right-card-actions {
    display: flex;
  }

  .not-the-right-card-actions .btn:first-of-type {
    margin-right: 12px;
  }

  .card-main-col1 {
    min-width: 324px;
  }

  .card-main-col2 {
    width: 100%;
  }

  .card-main-col1 .stock-image {
    display: none;
  }

  .content-header-main-actions {
    display: grid;
    column-gap: 16px;
    grid-template-columns: auto auto auto;
  }

  .market-places {
    height: 216px;
    max-height: 216px;
    min-height: 216px;
  }

  .market-place.card-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    cursor: auto;
  }

  .market-place.card-value .card-value-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .market-place.card-value .card-value-header p{
    margin-left: 16px;
  }

  .market-place.card-value .values{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
  }
  .market-place.card-value .value {
    display: flex;
    flex-direction: column;
  }

  .market-place.card-value .value p {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 14px;
    opacity: .7;
    margin-bottom: 3px;
  }

  .market-place.card-value .value span {
    font-size: 14px;
  }

  .market-place.card-value:hover {
    box-shadow: none;
    border: 1px solid rgba(46, 60, 84, .08);
  }

  .market-place.card-value .more-info {
    position: relative;
  }

  .market-place.card-value .more-info p{
    display: none;
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
    position: absolute;
    width: 100%;
    background: white;
    z-index: 2000;
    line-height: 150%;
    padding: 12px;
    width: 280px;
    left: -182px;
    top: 26px;
    box-shadow: 0px 0px 20px rgba(46, 60, 84, .4);
  }

  .market-place.card-value .more-info p::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    left: 164px;
  }

  .market-place.card-value .more-info:hover p{
    display: flex;
  }

  .card-main-info .info input {
    min-height: 36px;
    max-height: 36px;
    font-weight: bold;
  }

  .card-main-info .info.editable {
    margin-bottom: 15px;
  }

  .card-main-info-row2 select {
    width: 100%;
  }

  .card-main-info-row2 textarea {
    width: 100%;
    min-height: 94px;
  }


  .card-images-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 168px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-images-container > div {
    width: 113px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 24px;
  }

  .card-images-container img {
    width: 100%;
    margin-bottom: 5px;
  }

  .card-images-container span {
    margin-bottom: 5px;
    display: block;
    opacity: .7;
  }

  .card-main {
    position: relative;
    padding-right: 168px;
    height: 736px;
  }

  .settings-container {
    background: white;
    width: 100%;
    padding: 24px;
    margin-bottom: 24px;
  }

  .settings-container.custom-fields {
    .field-header {
      display: flex;
      justify-content: space-between;
      h2 {
        display: flex;
        align-items: center;
      }
      margin-bottom: 24px;
    }
    .fields-container {
      /* display: grid;
      grid-template-columns: auto auto auto auto; */
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      column-gap: 16px;
      row-gap: 24px;
    }
    .field-body {
      width: calc(25% - 12px);
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .field-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        /* margin-bottom: 8px; */
        .heading-input {
          padding: 8px 0;
          min-height: auto;
          font-weight: bold;
        }
        &.is-edit {
          margin-bottom: 8px;
          .heading-input {
            padding: 12px;
            font-weight: normal;
          }
        }
      }
      input {
        width: 100%;
        background: white;
      }
      .custom-field-edit {
        display: flex;
        align-items: center;
        column-gap: 4px;
      }
      img, svg {width: 20px; height: 20px; cursor: pointer}
    }
  }

  .settings-container.export-settings {
    .title {
      display: flex;
      justify-content: space-between;
    }
    .export-options {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 16px;
      row-gap: 24px;
      margin: 24px 0;
      width: 100%;
    }
    .export-option {
      border: 1px solid rgba(23, 35, 55, 0.1);
      padding: 12px;
      position: relative;
      min-height: 184px;
      .switch {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
      }
      .export-option-logo {
        img {
          width: 30px;
          height: 30px;
        }
        width: 40px;
        height: 40px;
        border: 1px solid rgba(23, 35, 55, 0.1);
        justify-content: center;
        display: flex;
        align-items: center;
      }
      .description {
        h3 {
          font-family: 'Roboto', serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
        }
        p {
          font-family: 'Roboto', serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          max-width: 420px;
          color: rgba(23, 35, 55, 0.7);
        }
      }
      .setting-to {
        display: flex;
        bottom: 12px;
        position: absolute;
        cursor: pointer;
        color: red;
        a {
          font-family: 'Roboto', serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    h2 {
      display: flex;
      align-items: center;
    }
  }

  .settings-container .other-settings {
    margin-top: 1.5rem;
  }

  h3.underline-style {
    position: relative;
    margin-bottom: 24px;
  }

  h3.underline-style::after {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    border-radius: 24px;
    position: absolute;
    bottom: -8px;
    left: 0;
    background: #4879CD;
  }

  .other-settings-inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
  }

  .other-settings-inputs > div {
    margin: 12px;
  }

  .fw input, .fw select {
    // margin-top: 6px;
    width: 416px;
  }

  .hw input, .hw select {
    /* margin-top: 6px; */
    width: 196px;
  }

  .qw input, .qw select {
    margin-top: 6px;
    width: 98px;
  }


  @media screen and (max-width: 1280px) {
    .card-main-col1 .user-submitted-image {
      display: flex;
      justify-content: space-between;
    }

    .card-main-col1 .user-submitted-image img {
      width: 49%;
      display: block;
    }

    .card-main-col2 {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .card-main-col1 .user-submitted-image {
      display: flex;
      justify-content: space-between;
    }

    .card-main-col1 .user-submitted-image img {
      width: 100%;
    }

    .card-main-col1 .user-submitted-image .stock-image {
      display: none;
    }

    .card-main-col2 {
      display: flex;
    }
  }

  @media screen and (max-width: 1000px) {
    .card-main-col1 .user-submitted-image {
      display: flex;
      justify-content: space-between;
    }

    .card-main-col1 .user-submitted-image img {
      width: 49%;
      display: block;
    }
    .card-main-col1 .user-submitted-image .stock-image {
      display: block;
    }

    .card-main-col2 {
      display: none;
    }
  }


  @media screen and (max-width: 820px) {

    .card-main-info .info h4 {
      font-size: 15px;
    }

    .card-main {
      flex-direction: column;
    }

    .card-main .btn.primary {
      width: 100%;
    }

    .card-main .card-main-col1 {
      display: flex;
      width: 100%;
      max-width: 100%;
      align-items: flex-end;
    }

    .card-main .card-main-info-column .market-places {
      margin-left: 24px;
    }

    .content-header-main-actions {
      display: none;
    }
  }

  @media screen and (max-width: 538px) {
    .card-main .card-main-col1 {
      flex-direction: column;
      align-items: center;
    }
    .card-main .card-main-info-column .market-places {
      margin-left: 0px;
    }

    .card-main .add-to-remove-from-collection-button {
      flex-direction: column;
    }

    .card-main .add-to-remove-from-collection-button .btn.primary {
      flex-direction: column;
      text-align: center;
      padding: 16px;
    }

    .card-main .add-to-remove-from-collection-button .btn.primary b{
      white-space: normal;
      margin-top: 12px;
    }

    .card-main .btn.primary.dark:last-of-type {
      margin-left: 0;
      margin-top: 8px;
    }

    .not-the-right-card-actions {
      flex-direction: column;
      margin-bottom: 24px;
    }

    .not-the-right-card-actions .btn.secondary:first-of-type {
      margin-right: 0;
      margin-bottom: 8px;
    }

    .matches-found-notify {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 359px) {
    .card-main-col1 {
      width: 100%;
      min-width: auto;
      padding: 0;
    }
    .card-main-col1 .user-submitted-image {
      width: 100%;
      max-width: 100%;
    }

    .card-main-info-column .market-places {
      width: 100%;
      padding: 0 12px;
    }

    .card-main-info-column .market-place {
      width: 100%;
      max-width: 100%;
    }

    .card-main-info-col2 {
      margin-left: 12px;
    }
  }

  .categories {
    .category-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    .selected-category-settings {
      display: flex;
      align-items: flex-end;
      margin-top: 12px;
      margin-left: -16px;
    }

    .selected-category-settings .btn{
      height: 40px;
    }

    .selected-category-settings > div {
      margin-left: 16px;
      margin-top: 12px;
    }
  }

  .other-settings.custom-fields .hw {
    position: relative;
  }

  .other-settings.custom-fields .hw > div {
    display: flex;
    max-width: 196px;
  }
  .other-settings.custom-fields .hw:hover img{
    display: inline;
  }

  .other-settings.custom-fields label:hover {
    color: rgba(46, 60, 84, .52);
  }

  .other-settings.custom-fields label:focus:hover {
    color: rgba(46, 60, 84, 1);
  }

  .other-settings.custom-fields .hw img {
    margin-left: 8px;
    display: none;
  }

  .additional-scans-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    width: 100%;
    .buy-button {
      margin-left: 50px;
    }
  }

  .settings-container .tabs-input{
    margin-top: 24px;
    margin-bottom: 16px;
  }


  .account-info-row1 {
    display: flex;
    margin-top: 24px;
    .col1 {
      min-width: 277px;
      justify-content: center;
      display: flex;
    }
  }

  //.account-info-row3 {
  //  display: flex;
  //  margin-top: 24px;
  //}

  .account-info-row1 .profile-picture {
    width: 164px;
    text-align: center;
    margin-top: 12px;
  }

  .account-info-row1 .profile-picture img {
    width: 164px;
    height: 164px;
    border-radius: 100%;
    vertical-align: inherit;
  }

  .account-info-row1 .col2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 80px;
    //margin-top: 8px;
  }
  //.account-info-row1 .col2 > div{
  //  margin-top: 16px;
  //}

  .account-info-row1 .username {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    .fw {
      label {
        //margin: 0;
        font-family: 'Roboto', serif;
        /* font-style: normal; */
        /* font-weight: 500; */
        /* font-size: 16px; */
        /* line-height: 19px; */
      }
      /* input { margin-top: 8px;} */
    }
  }

  //.account-info-row1 .username > div{
  //  margin-top: 16px;
  //}

  .account-info-row2 {
    display: flex;
    margin-top: 16px;
    width: 100%;
    justify-content: space-between;
  }

  .account-info-row2 .auto-login {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .account-info-row2 .auto-login > div:last-of-type {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  .account-info-row2 .auto-login .checkbox {
    margin-right: 8px;
  }

  .account-info-row3 > div .title {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .account-info-row4 {
    width: 100%;
    margin-top: 12px;
  }

  /*BAR*/

  .cprogress-circle {
    position: relative;
    /* so that children can be absolutely positioned */
    min-width: 5em;
    min-height: 5em;
    background-color: #EDF2FB;
    border-radius: 50%;
  }

  .cprogress-circle:after {
    position: absolute;
    top: 0.348em;
    left: 0.359em;
    text-align: center;
    display: block;
    border-radius: 50%;
    min-width: 4.3em;
    min-height: 4.3em;
    background-color: white;
    content: " ";
  }

  /* Text inside the control */
  .cprogress-circle .cprogress-value {
    position: absolute;
    line-height: 5em;
    min-width: 100%;
    padding-left: 2px;
    text-align: center;
    display: block;
    color: #2E3C54;
    z-index: 2;
  }

  .left-half-clipper {
    /* a round circle */
    border-radius: 50%;
    min-width: 5em;
    min-height: 5em;
    position: absolute;
    /* needed for clipping */
    clip: rect(0, 5em, 5em, 2.5em);
    /* clips the whole left half*/
  }

  /* when p>50, don't clip left half*/
  .cprogress-circle.over50 .left-half-clipper {
    clip: rect(auto, auto, auto, auto);
  }

  .value-bar {
    /*This is an overlayed square, that is made round with the border radius,
   then it is cut to display only the left half, then rotated clockwise
   to escape the outer clipping path.*/
    position: absolute;
    /*needed for clipping*/
    clip: rect(0, 2.5em, 5em, 0);
    min-width: 5em;
    min-height: 5em;
    border-radius: 50%;
    border: 0.45em solid #4879CD;
    /*The border is 0.35 but making it larger removes visual artifacts */
    /*background-color: #4D642D;*/
    /* for debug */
    box-sizing: border-box;

  }

  /* Progress bar filling the whole right half for values above 50% */
  .cprogress-circle.over50 .first50-bar {
    /*Progress bar for the first 50%, filling the whole right half*/
    position: absolute;
    /*needed for clipping*/
    clip: rect(0, 5em, 5em, 2.5em);
    background-color: #505B70;
    border-radius: 50%;
    min-width: 5em;
    min-height: 5em;
  }

  .cprogress-circle:not(.over50) .first50-bar {
    display: none;
  }


  /* Progress bar rotation position */
  .cprogress-circle.p0 .value-bar {
    display: none;
  }

  .cprogress-circle.p1 .value-bar {
    transform: rotate(4deg);
  }

  .cprogress-circle.p2 .value-bar {
    transform: rotate(7deg);
  }

  .cprogress-circle.p3 .value-bar {
    transform: rotate(11deg);
  }

  .cprogress-circle.p4 .value-bar {
    transform: rotate(14deg);
  }

  .cprogress-circle.p5 .value-bar {
    transform: rotate(18deg);
  }

  .cprogress-circle.p6 .value-bar {
    transform: rotate(22deg);
  }

  .cprogress-circle.p7 .value-bar {
    transform: rotate(25deg);
  }

  .cprogress-circle.p8 .value-bar {
    transform: rotate(29deg);
  }

  .cprogress-circle.p9 .value-bar {
    transform: rotate(32deg);
  }

  .cprogress-circle.p10 .value-bar {
    transform: rotate(36deg);
  }

  .cprogress-circle.p11 .value-bar {
    transform: rotate(40deg);
  }

  .cprogress-circle.p12 .value-bar {
    transform: rotate(43deg);
  }

  .cprogress-circle.p13 .value-bar {
    transform: rotate(47deg);
  }

  .cprogress-circle.p14 .value-bar {
    transform: rotate(50deg);
  }

  .cprogress-circle.p15 .value-bar {
    transform: rotate(54deg);
  }

  .cprogress-circle.p16 .value-bar {
    transform: rotate(58deg);
  }

  .cprogress-circle.p17 .value-bar {
    transform: rotate(61deg);
  }

  .cprogress-circle.p18 .value-bar {
    transform: rotate(65deg);
  }

  .cprogress-circle.p19 .value-bar {
    transform: rotate(68deg);
  }

  .cprogress-circle.p20 .value-bar {
    transform: rotate(72deg);
  }

  .cprogress-circle.p21 .value-bar {
    transform: rotate(76deg);
  }

  .cprogress-circle.p22 .value-bar {
    transform: rotate(79deg);
  }

  .cprogress-circle.p23 .value-bar {
    transform: rotate(83deg);
  }

  .cprogress-circle.p24 .value-bar {
    transform: rotate(86deg);
  }

  .cprogress-circle.p25 .value-bar {
    transform: rotate(90deg);
  }

  .cprogress-circle.p26 .value-bar {
    transform: rotate(94deg);
  }

  .cprogress-circle.p27 .value-bar {
    transform: rotate(97deg);
  }

  .cprogress-circle.p28 .value-bar {
    transform: rotate(101deg);
  }

  .cprogress-circle.p29 .value-bar {
    transform: rotate(104deg);
  }

  .cprogress-circle.p30 .value-bar {
    transform: rotate(108deg);
  }

  .cprogress-circle.p31 .value-bar {
    transform: rotate(112deg);
  }

  .cprogress-circle.p32 .value-bar {
    transform: rotate(115deg);
  }

  .cprogress-circle.p33 .value-bar {
    transform: rotate(119deg);
  }

  .cprogress-circle.p34 .value-bar {
    transform: rotate(122deg);
  }

  .cprogress-circle.p35 .value-bar {
    transform: rotate(126deg);
  }

  .cprogress-circle.p36 .value-bar {
    transform: rotate(130deg);
  }

  .cprogress-circle.p37 .value-bar {
    transform: rotate(133deg);
  }

  .cprogress-circle.p38 .value-bar {
    transform: rotate(137deg);
  }

  .cprogress-circle.p39 .value-bar {
    transform: rotate(140deg);
  }

  .cprogress-circle.p40 .value-bar {
    transform: rotate(144deg);
  }

  .cprogress-circle.p41 .value-bar {
    transform: rotate(148deg);
  }

  .cprogress-circle.p42 .value-bar {
    transform: rotate(151deg);
  }

  .cprogress-circle.p43 .value-bar {
    transform: rotate(155deg);
  }

  .cprogress-circle.p44 .value-bar {
    transform: rotate(158deg);
  }

  .cprogress-circle.p45 .value-bar {
    transform: rotate(162deg);
  }

  .cprogress-circle.p46 .value-bar {
    transform: rotate(166deg);
  }

  .cprogress-circle.p47 .value-bar {
    transform: rotate(169deg);
  }

  .cprogress-circle.p48 .value-bar {
    transform: rotate(173deg);
  }

  .cprogress-circle.p49 .value-bar {
    transform: rotate(176deg);
  }

  .cprogress-circle.p50 .value-bar {
    transform: rotate(180deg);
  }

  .cprogress-circle.p51 .value-bar {
    transform: rotate(184deg);
  }

  .cprogress-circle.p52 .value-bar {
    transform: rotate(187deg);
  }

  .cprogress-circle.p53 .value-bar {
    transform: rotate(191deg);
  }

  .cprogress-circle.p54 .value-bar {
    transform: rotate(194deg);
  }

  .cprogress-circle.p55 .value-bar {
    transform: rotate(198deg);
  }

  .cprogress-circle.p56 .value-bar {
    transform: rotate(202deg);
  }

  .cprogress-circle.p57 .value-bar {
    transform: rotate(205deg);
  }

  .cprogress-circle.p58 .value-bar {
    transform: rotate(209deg);
  }

  .cprogress-circle.p59 .value-bar {
    transform: rotate(212deg);
  }

  .cprogress-circle.p60 .value-bar {
    transform: rotate(216deg);
  }

  .cprogress-circle.p61 .value-bar {
    transform: rotate(220deg);
  }

  .cprogress-circle.p62 .value-bar {
    transform: rotate(223deg);
  }

  .cprogress-circle.p63 .value-bar {
    transform: rotate(227deg);
  }

  .cprogress-circle.p64 .value-bar {
    transform: rotate(230deg);
  }

  .cprogress-circle.p65 .value-bar {
    transform: rotate(234deg);
  }

  .cprogress-circle.p66 .value-bar {
    transform: rotate(238deg);
  }

  .cprogress-circle.p67 .value-bar {
    transform: rotate(241deg);
  }

  .cprogress-circle.p68 .value-bar {
    transform: rotate(245deg);
  }

  .cprogress-circle.p69 .value-bar {
    transform: rotate(248deg);
  }

  .cprogress-circle.p70 .value-bar {
    transform: rotate(252deg);
  }

  .cprogress-circle.p71 .value-bar {
    transform: rotate(256deg);
  }

  .cprogress-circle.p72 .value-bar {
    transform: rotate(259deg);
  }

  .cprogress-circle.p73 .value-bar {
    transform: rotate(263deg);
  }

  .cprogress-circle.p74 .value-bar {
    transform: rotate(266deg);
  }

  .cprogress-circle.p75 .value-bar {
    transform: rotate(270deg);
  }

  .cprogress-circle.p76 .value-bar {
    transform: rotate(274deg);
  }

  .cprogress-circle.p77 .value-bar {
    transform: rotate(277deg);
  }

  .cprogress-circle.p78 .value-bar {
    transform: rotate(281deg);
  }

  .cprogress-circle.p79 .value-bar {
    transform: rotate(284deg);
  }

  .cprogress-circle.p80 .value-bar {
    transform: rotate(288deg);
  }

  .cprogress-circle.p81 .value-bar {
    transform: rotate(292deg);
  }

  .cprogress-circle.p82 .value-bar {
    transform: rotate(295deg);
  }

  .cprogress-circle.p83 .value-bar {
    transform: rotate(299deg);
  }

  .cprogress-circle.p84 .value-bar {
    transform: rotate(302deg);
  }

  .cprogress-circle.p85 .value-bar {
    transform: rotate(306deg);
  }

  .cprogress-circle.p86 .value-bar {
    transform: rotate(310deg);
  }

  .cprogress-circle.p87 .value-bar {
    transform: rotate(313deg);
  }

  .cprogress-circle.p88 .value-bar {
    transform: rotate(317deg);
  }

  .cprogress-circle.p89 .value-bar {
    transform: rotate(320deg);
  }

  .cprogress-circle.p90 .value-bar {
    transform: rotate(324deg);
  }

  .cprogress-circle.p91 .value-bar {
    transform: rotate(328deg);
  }

  .cprogress-circle.p92 .value-bar {
    transform: rotate(331deg);
  }

  .cprogress-circle.p93 .value-bar {
    transform: rotate(335deg);
  }

  .cprogress-circle.p94 .value-bar {
    transform: rotate(338deg);
  }

  .cprogress-circle.p95 .value-bar {
    transform: rotate(342deg);
  }

  .cprogress-circle.p96 .value-bar {
    transform: rotate(346deg);
  }

  .cprogress-circle.p97 .value-bar {
    transform: rotate(349deg);
  }

  .cprogress-circle.p98 .value-bar {
    transform: rotate(353deg);
  }

  .cprogress-circle.p99 .value-bar {
    transform: rotate(356deg);
  }

  .cprogress-circle.p100 .value-bar {
    transform: rotate(360deg);
  }

  .custom-control-label:before{
    background-color:white;
  }

  .custom-checkbox {
    display: flex;
    align-items: center;
    label {
      padding-left: 6px;
      &::before, &::after {
        top: -1px;
      }
    }
  }

  .checkbox input {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .tabs-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background: #0D1626;
    cursor: pointer;
    padding: 4px;
    border: 1px solid #3A4C6B;
  }

  .tabs-input .tab {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 11px;
    padding-right: 13px;
  }

  .tabs-input .tab img {
    margin-right: 11px;
  }

  .tabs-input .tab.active {
    background: #435066;
  }

  .tabs-input.light {
    background: #E1E5EB;
    border: 1px solid rgba(46, 60, 84, .1);
    justify-content: flex-start;
    width: fit-content;
  }

  .tabs-input.light .tab.active {
    background: white;
  }

  .membership-item {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(46, 60, 84, .1);
    margin-right: 16px;
    min-width: 245px;
    position: relative;
  }


  p {
    margin-bottom: 0;
  }

  .btn.primary.disabled {
    background: #D5D8DD;
    cursor: auto;
    pointer-events: none;
    color: rgba(46, 60, 84, .5);
  }

  .buy-additional-scans {
    .buy-form {
      width: 450px;
    }
  }

  .memberships {
    margin: 2rem 0 6px 0;
  }

  .memberships .title-header{
    h3 {
      //text-decoration: underline solid #4879CD 3px;
      height: max-content;
      border-bottom: solid #4879CD 3px;
      padding-bottom: .5rem;
    }
    div {
      display: flex;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      //color: #f5878c;
      color: red;
      text-decoration: underline;
      cursor: pointer;
    }
    .cancel-link {
      display: flex;
      align-items: center;
      margin-right: 36px;
    }
  }

  .memberships .membership-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    & > div {
      width: 32%;
    }
    .title {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .info-container {
      border: 1px solid rgba(46, 60, 84, .1);
      padding: 20px;
      display: flex;
      align-items: center;
    }

    .info-container .info {
      margin: 12px 12px 12px 24px;
    }

    .info-container .info p:first-of-type {
      font-size: 13px;
      opacity: .7;
      margin-bottom: 4px;
    }

    .subscription-info .info-container{
      flex-direction: column;
      align-items: normal;
    }

    .subscription-info .info-container .info{
      margin: 0;

    }

    .subscription-info .info-container .info.r1 {
      border-bottom: 1px solid rgba(46, 60, 84, .1);
      padding-bottom: 17px;
      margin-bottom: 17px;
    }
  }
  .template-container {
    margin: 20px 0;
    .title-textarea-edit {
      display: flex;
      justify-content: space-between;

      .label-edit {
        display: flex;
        align-items: center;
        column-gap: .5rem;
        font-weight: 500;
        input { width: fit-content; }
        img {
          cursor: pointer;
          width: 16px;
          height: 16px;
          position: relative;
          top: -2px;
        }
        .label-non-edit-state { border: none;};
      }
      .option-buttons {
        display: flex;
      }
    }

    .fw {
      display: flex;
      margin-top: 12px;
      input { margin: 0}
      .title {
        min-height: 30px;
        padding: 6px;
      }
      textarea {
        width: 100%;
        min-height: 300px;
      }
    }
    .btn-template {
      margin: 1px 0 1px 20px;
      height: fit-content;
      min-width: 240px;
      background: rgba(234, 53, 83, 0.04);
    }
    .edit-state {
      background: #2B2D3B;
      color: white;
    }
    .description-textarea-edit {
      display: flex;
      justify-content: space-between;
      margin-right: 260px;
      .label-edit {
        display: flex;
        align-items: center;
        column-gap: .5rem;
        font-weight: 500;
      }
      .edit-option-buttons {
        padding: 1px;
        display: flex;
        align-items: stretch;
        column-gap: 3px;
        border: 1px solid rgba(23, 35, 55, 0.1);
        border-bottom: 0;
        .btn {
          margin-right: 40px;
        }
        button {
          padding: 10px 1rem;
          color: #172337aa;
          transition: .2s;
          cursor: pointer;
          background-color: transparent;
        }
      }
    }
    .edit-state {
      background-color: #2B2D3B !important;
      color: white !important;
    }
    .description-fw {
      margin-top: 0;
      .edit-state.description {
        border: 1px solid #2B2D3B;
        padding: 12px;
      }
    }
    .description-editor-wrapper {
      border: 1px solid rgba(23, 35, 55, 0.1);
      min-height: 300px;
      width: 100%;
      padding: .75rem;
      .public-DraftStyleDefault-block {
        margin: 0;
      }
    }
    .description-option-buttons {
      button {
        margin-bottom: 20px;
      }
    }
  }

  .settings-template {
    .btn-new-template {
      width: fit-content;
    }
  }

  .sku {
    .checkbox-row {
      display: flex;
      align-items: center;
    }
    .format-settings {
      .checkbox-row {
        column-gap: 1rem;
      }
      .format-row {
        /* flex: 1; */
        p {
          font-weight: 500;
          margin-bottom: .5rem;
        }
        select, input {
          width: 100%;
          //height: 48px !important;
        }
      }
    }
  }
  .save-changes {
    width: fit-content;
  }
}

.banner-image {
  cursor: pointer;
  img {
    width: 300px;
    border: 1px dashed black;
    margin: 24px 0;
  }
  p {
    color: red;
    cursor: pointer;
    margin-bottom: 24px;
  }
}

.banner-image-placement {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
  select {
    min-width: 300px;
  }
}
p.micro-uppercase {
  font-size: 10px;
  opacity: .5;
  margin-bottom: 6px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
}

.radio-inputs-container {
  display: flex;
}

.radio-inputs-container .radio-input {
  margin-right: 16px;
}

input[type="radio"] {
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 24px;
  height: 24px;
  border: 0.15em solid rgba(46, 60, 84, .3);
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin-right: 6px;
}

.radio-input {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.settings-sliding-overlay {
  top: calc(72px + 96px) !important;
  z-index: 3000;
  .slide-pane {
    width: 20rem !important;
    .slide-pane__content {
      padding: 0;
      background-color: #F2F4F7;
    }
  }
}
.settings-sliding-data-field {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 1.5rem 1.75rem 1.5rem 2rem;
    h3 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .close-sliding {
    cursor: pointer;
    background-color: transparent;
  }
  .content {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .data-field-btn {
      width: fit-content;
      background-color: white;
      line-height: 18px;
      padding: 8px 12px;
      transition: .2s;
      &:hover {
        background-color: #EA3553;
        color: white;
      }
    }
  }
}

.settings, .card-details {
  .batches-container {
    display: flex;
    margin: -8px;
    flex-wrap: wrap;
  }

  .sec-header .batch-details-info {
    margin-left: -24px;
  }

  .sec-header .info-item {
    margin-left: 24px;
  }

  .sec-header .info-item span{
    font-size: 13px;
    opacity: .7;
    display: block;
    margin-bottom: 4px;
  }

  .sec-header .info-item.status div{
    display: flex;
    align-items: center;
  }
  .sec-header .info-item.status.open p{
    color: #396DC5;
  }
  .sec-header .info-item.status.closed p{
    color: #1F9C77;
  }
  .sec-header .info-item.status.pending p{
    color: #DA9D26;
  }

  .additional-batch-info {
    cursor: pointer;
    color: #EA3553;
  }

  .batch-card {

    background: #FFFFFF;
    border: 1px solid rgba(46, 60, 84, 0.1);
    width: 200px;
    height: 212px;
    max-height: 212px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: box-shadow .3s;
    color: rgba(46, 60, 84, 1);
    margin: 8px;
    font-size: 14px;
    padding-top: 18px;
    position: relative;
  }


  .batch-card:hover {
    box-shadow: 0px 0px 6px rgba(46, 60, 84, .12);
  }

  .batch-card:hover .batch-card-select {
    border: 1px solid #Ea2353;
  }

  .batch-card-images {
    width: 164px;
    height: 128px;
    display: flex;
    justify-content: space-between;
  }

  .batch-card-images > div {
    width: 79px;
    height: 128px;
    overflow: hidden;
  }

  .batch-card-images img {
    width: 100%;
    height: 100%;
  }

  .batch-card-name {
    margin-top: 11px;
    opacity: .8;
    line-height: 140%;
    width: 164px;
    height: 37px;
    overflow: hidden;
  }

  .batch-card-select {
    position: absolute;
    width: 22px;
    height: 22px;
    background: white;
    border: 1px solid #EEF0F4;
    border-radius: 100%;
    top: -11px;
    right: -11px;
    box-shadow: 0px 0px 4px rgba(46, 60, 84, .16);
    transition: border .3s, background .3s;
  }

  .batch-card.selected {
    box-shadow: 0px 0px 6px rgba(243, 53, 83, .3);
  }

  .batch-card.selected .batch-card-select{
    background-color: #EA3553;
    background-image: url('../images/checkedIcon.svg');
    background-position: center;
    background-size: 16px;
  }


  .btn.secondary.on-dark {
    color: #FF4564;
  }



  .btn.secondary.on-dark:hover {
    color: white;
  }

  .btn img {
    margin-right: 7px;
  }

  .input {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    background: #435066;
    transition: background .3s;
  }

  .input.dropdown {
    cursor: pointer;
  }


  .input img {
    margin-right: 12px;
  }

  .input:hover {
    background: #39465C;
  }

  .content-header-main .column {
    display: flex;
    align-items: center;
  }

  .content-header-main .column.right {
    min-width: 410px;
    justify-content: flex-end;
    width: 100%;
  }

  .content-header-main .column.right .input {
    margin-right: 16px;
  }




  .text-input {
    display: flex;
    flex-direction: column;
  }

  .text-input label {
    display: block;
    margin-bottom: 4px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }





  .more-options-menu {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 48px;
    transition: background .3s;
  }

  .more-options-menu > img {
    display: none;
  }

  .more-options-menu > img {
    opacity: .6;
    transition: opacity .3s;
  }

  .more-options-menu .menu-options {
    position: absolute;
    background: white;
    box-shadow: 0 8px 12px rgba(46, 60, 84, .22);
    right: 0;
    top: 100%;
    flex-direction: column;
    display: none;
  }

  .more-options-menu .menu-options .option {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(46, 60, 84, .12);
  }

  .more-options-menu:hover {
    opacity: 1;
  }

  .more-options-menu:hover > img{
    opacity: 1;
  }

  .has-menu.open {
    box-shadow: none;
    cursor: auto;
  }

  .has-menu:hover .more-options-menu > img{
    display: flex;
  }

  .has-menu .more-options-menu > img{
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  .has-menu {
    position: relative;
  }

  .has-menu .more-options-menu {
    z-index: 10;
    cursor: pointer;
  }



  .has-menu.menu-open::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(46,60,84,.2);
    display: flex;
    z-index: 1;
    top: 0;
    left: 0;
  }
  .has-menu.menu-open > .more-options-menu .menu-options{
    display: flex;
  }

  .has-menu.menu-open .more-options-menu{
    background: white;
  }

  .has-menu.menu-open .more-options-menu > img {
    display: flex;
    opacity: 1;
  }



  .input.dropdown {
    position: relative;
  }

  .dropdown-input-options {
    position: absolute;
    background: white;
    color:#172337;
    right: 0;
    top: 100%;
    box-shadow: 0px 12px 23px rgba(46, 60, 84, .4);
    display: none;
    z-index: 1000;
    pointer-events: none;
  }

  .dropdown-input-options select {
    width: 100%;
    height: 100%;
  }

  .dropdown-input-container {
    position: relative;
  }


  .dropdown-input-container.open .dropdownarrow {
    transform: rotate(180deg);
  }

  .sec-footer {
    justify-content: space-between;
    padding: 0 24px;
  }

  .sec-footer > div{
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .sec-footer > div:first-of-type > div:first-of-type{
    margin-right: 16px;
  }

  .sec-footer > div:last-of-type {
    color: #EA3553;
  }

  .sec-footer > div:last-of-type img{
    margin-right: 4px;

  }

  footer {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 235px);
  }


  .sec-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 24px;
  }

  .sec-header .batch-details-info {
    display: flex;
    align-items: center;
  }

  //.card-main {
  //  background: white;
  //  width: 100%;
  //  margin: auto;
  //  display: flex;
  //}

  .matches-found-notify {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.add-photo-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  .modal-dialog {
    min-width: 31rem;
    max-width: 90%;
  }
}