.main-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 35, 55, .8);
  z-index: 2000;
  backdrop-filter: blur(10px);
}

.main-popup-container {
  background: transparent;
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2100;
  padding: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.main-popup {
  position: relative;
  z-index: 2200;
  width: 100%;
  //height: 100%;
  display: flex;
  flex-direction: column;
  background: #F7F9FC;
  padding-top: 72px;
  margin: auto;
  max-width: 1120px;
}

.main-popup .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 72px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(46, 60, 84, .1);
  background: #F7F9FC;
  position: absolute;
  top: 0px;
}

.main-popup .header h2 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 28px;
  letter-spacing: 1px;
  color: #172337;
  margin-left: 32px;
  font-weight: bold;
  margin-bottom: 0;
}

.main-popup .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px 32px 32px;
  overflow: auto;
}

.main-popup .header .close-popup {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  cursor: pointer;
  background: #F7F9FC;
  transition: background .3s;
}


.main-popup .header .close-popup:hover {
  background: rgba(240, 244, 250, 1);
}


.main-popup .inputs-container {
  width: 100%;
  .new-batch-params {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }
  .fw {
    margin-top: 10px;
    input { width: 100% }
    select { width: 100%; }
  }
}

.main-popup .inputs-container .text-input {
  margin-bottom: 20px;
}


.main-popup .btn.primary {
  width: 100%;
}


.main-popup .big-text input {
  height: 82px;
  font-size: 30px;
}

.main-popup .footer {
  width: 100%;
  background: white;
  padding: 12px 28px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.main-popup .footer::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: transparent;
  pointer-events: none;
  box-shadow: -12px 0px 24px rgba(46, 60, 84, .2);
}

//.main-popup .body {
//  height: 100%;
//}

.other-popup-settings-container {
  width: 100%;
  margin-top: 16px;

}

h3 {
  font-weight: bold;
  font-size: 1.17rem;
}

h3.underline-style {
  position: relative;
  margin-bottom: 24px;
}

h3.underline-style::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  border-radius: 24px;
  position: absolute;
  bottom: -8px;
  left: 0;
  background: #4879CD;
}

.other-settings-inputs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
}



.other-settings-inputs > div {
  margin: 12px;
  margin-right: 0;
}

.fw input, .fw select {
  /* margin-top: 6px; */
  width: 416px;
}

.hw input, .hw select {
  /* margin-top: 6px; */
  width: 196px;
}

.qw input, .qw select {
  margin-top: 6px;
  width: 98px;
}


.other-popup-settings-container .other-settings {
  margin-top: 32px;
}


.create-batch .main-popup {
  max-width: 1120px;
  max-height: 100%;
}

.create-category .main-popup {
  max-width: 730px;
  max-height: 100%;
}

.create-category .main-popup .footer,
.create-batch .main-popup .footer {
  flex-direction: row-reverse;
}

.create-batch .main-popup .footer .btn.primary,
.create-category .main-popup .footer .btn.primary{
  width: 120px;
}

.export-batch .main-popup {
  max-width: 730px;
  max-height: 368px;
  line-height: 145%;
}

.add-cards .main-popup {
  max-width: 730px;
  max-height: 480px;

  .body > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.export-batch-options-container {
  display: flex;
  align-items: center;
}

.fill-column .export-batch-options-container {
  flex-direction: column;
}

.fill-column .export-batch-options-container {
  width: 100%;
  flex-direction: column;
}


.hide-column .export-batch-options-container {
  width: 100%;
  flex-direction: column;
}



.main-popup .add-cards-options-container {
  display: flex;
  flex-direction: row;
  width: 584px;
  justify-content: space-between;
  margin-top: 16px;
}

.main-popup .add-cards-option {
  width: 284px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  padding-top: 16px;
  background: white;
  cursor: pointer;
  border: 1px solid rgba(46, 60, 84, .12);
  transition: box-shadow .3s;
}

.main-popup .add-cards-option:hover {
  box-shadow: 0px 4px 16px rgba(46, 60, 84, .16);
}

.main-popup .add-cards-option p {
  font-size: 13px;
  opacity: .7;
  line-height: 145%;
}

.main-popup .add-cards-option p:first-of-type {
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  margin-bottom: 6px;
}

.main-popup .add-cards-option .add-cards-images{
  display: flex;
  margin-bottom: 12px;
  img {
    margin-right: 20px;
    width: 45px;
    border: solid 2px #EA3553;
  }
}

.uploadcards-modal {
  .main-popup {
    max-width:  480px;
    max-height: 400px;
  }
}
.search_modal-content {
  .main-popup {
    max-width:  796px;
    max-height: 840px;
  }
  .search-container {
    max-width: 556px;
    .nav-search {
      display: flex;
      justify-content: space-between;
      .search-img {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .nav-tabs2 {
    justify-content: center;
    margin-bottom: 10px;
    border-bottom: none;
  }
  .nav-tabs2 a, .nav-tabs a.active {
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 2.00683px 2.00683px rgba(121, 133, 158, 0.21);
    padding: 12px 12px;
  }

  .nav-item {
    padding: 0;
    background: #e1e5ef;
    //&:hover {
    //  background: white;
    //}
  }

  .tab-content .tab-content2 {
    justify-content: center;
    display: flex;
  }
  .form-group {
    margin: 10px;
  }

  .listing_links {
    max-height: 430px;
    overflow-y: scroll;
  }
  .listing_links a {
    margin-top: 1rem;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #2376e5!important;
  }
}

.payment {
  .main-popup {
    max-width: 720px;
    .body {
      display: unset;
    }
  }
}

.payment-form-container-main {
  display: grid;
  row-gap: 16px;
  column-gap: 20px;
  grid-template-columns: auto;
  .stripe-container {
    background-color: white;
    padding: 20px 24px;
    border: 1px solid rgba(46, 60, 84, 0.1);
    margin-bottom: 8px;
  }
}

.payment-form-container-main .inputs-row1 {
  display: grid;
  column-gap: 20px;
  grid-template-columns: .5fr .5fr;
}

.payment-form-container-main .membership-pay {
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 500;
    font-size: 16px;
  }
}

.payment-form-container-main .inputs-row2 {
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: .5fr .5fr .5fr;
  margin-bottom: 24px;
}

.change-membership .main-popup {
  max-width: 730px;
  max-height: 440px;

  .body > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .select-membership {
    display: flex;
    width: 500px;
    justify-content: space-evenly;
  }
  .select-button {
    width: 60%;
  }
}

.alert .main-popup {
  max-width: 730px;
  max-height: 440px;

  .popup-yes-no-button.notice_yes_no {
    display: block;
    .primary {
      min-height: 24px;
      height: 24px;
    }
  }
  .popup-yes-no-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    .btn {
      margin: 5px 0;
    }
    .warning {
      background: $color-green;
      color: white;
    }
    .cancel {
      border: none;
      background: rgba(234, 53, 83, 0.04);
      color: #EA3553;
    }
  }
}

.change-membership .main-popup {
  max-width: 730px;
  max-height: 440px;

  .body > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .select-membership {
    display: flex;
    width: 500px;
    justify-content: space-evenly;
  }
  .select-button {
    width: 60%;
  }
}

.main_modal_data {
  margin-bottom: 20px;
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  p{
    text-align: center;
  }
}

.checkbox-option {
  background: white;
  border-bottom: 1px solid rgba(46, 60, 84, .1);
  display: flex;
  align-items: center;
  padding: 12px;
}

.checkbox-option .checkbox {
  margin-right: 8px;
}

.checkbox-option textarea {
  width: 100%;
  max-height: 120px;
  min-height: 120px;
}

.cancel-membership-popup .main-popup {
  max-width: 540px;
  max-height: 700px;
}

.add-banner-popup .main-popup {
  max-width: 800px;
  max-height: 600px;
}

.add-banner-popup .image-zone {
  margin: 1.5rem auto 2rem;
  img {
    max-width: 38rem;
    max-height: 20rem;
    object-fit: contain;
  }
}

.add-banner-popup .file-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border: 1px solid rgba(23, 35, 55, 0.1);
  width: 100%;
  input {
    all: unset;
    width: 100%;
  }
}

.main-popup {
  max-height: 100% !important;
}

.register-container, .change-membership-popup, .confirm-membership-popup {
  .memberships-container {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 24px;
    column-gap: 16px;
    .class-header {
      text-align: center;
      border-bottom: 1px solid rgba(46, 60, 84, 0.06);
      padding-bottom: 16px;
      margin: 0 8px;
      .membership-title {
        font-size: 16px;
      }
      .membership-pricing {
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        margin: 10px 0;
      }
      span {
        opacity: 0.7;
      }
    }
  }
  .membership-features {
    /* width: fit-content; */
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }
  .membership-item {
    padding: 24px 12px;
    margin: 0;
    background: white;
  }
  .membership-item.currently-subscribed {
    border: 1px solid #EA3553;
    box-shadow: 0px 0px 6px rgba(234, 53, 83, 0.3);
  }

  .membership-item.currently-subscribed::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #EA3553;
    top: 12px;
    right: 12px;
    z-index: 10;
    background-image: url('../images/checkedIcon.svg');
  }

  .membership-item.mvp::after {
    content: "";
    position: absolute;
    top: -9px;
    right: -39px;
    width: 0;
    height: 0;
    border-right: 60px solid transparent;
    border-left: 60px solid transparent;
    border-bottom: 60px solid #FF9D00;
    transform: rotate(45deg);
  }
  .membership-item.mvp::before {
    content: "Most popular";
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .11em;
    position: absolute;
    top: 15px;
    right: -13px;
    line-height: 12px;
    width: 5rem;
    text-align: center;
    z-index: 1;
    transform: rotate(45deg);
  }

  .membership-item .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 24px;
    border-bottom: 1px solid rgba(46, 60, 84, .12);
  }

  .membership-item .membership-title {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: bold;
  }

  .membership-item .membership-pricing {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .membership-item .membership-pricing p {
    font-size: 32px;
    margin-bottom: 4px;
    margin-top: 12px;
  }

  .membership-item .btn.primary,
  .membership-item .btn.secondary {
    border-radius: 0;
    font-size: 15px;
    letter-spacing: 0.08em;
    font-weight: 700;
  }

  .btn.primary[type='submit'] {
    min-width: 8rem;
    width: fit-content;
    margin: 0 auto;
    border-radius: 2px;
  }

  .membership-item .btn.primary {
    color: rgba(255, 255, 255, .9);
  }

  .membership-item .btn.secondary {
    color: rgba(234, 53, 83, .9);
    background-color: #EA35531A;
    border: 0;
  }


  .membership-feature {
    display: flex;
    align-items: flex-end;
    line-height: 16px;
    justify-content: center;
    column-gap: 4px;
    min-height: 25px;
    position: relative;
    top: -4px;
  }

  .membership-feature img {
    margin-right: 4px;
    position: relative;
    top: 1px;
  }

  .membership-feature .feature-value {
    font-weight: bold;
  }

  .membership-feature .feature-title {
    font-family: 'Bebas Neue';
    opacity: .9;
    position: relative;
    top: 1px;
  }
}

.confirm-membership-popup {
  .body {
    background: #F2F4F7;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    justify-content: center;
  }
  .memberships-container {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 24px;
    column-gap: 16px;
  }
  .plan-current, .plan-new {
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 12px;
    }
    margin: 0 4px;
  }
  .membership-diff {
    margin: 26px 4px;
    display: grid;
    grid-row-gap: 11px;
    div {
      display: flex;
      justify-content: space-between;
      span {
        font-weight: 400;
        font-size: 14px;
        color: #2E3C54;
      }
      p {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

.buy-scans-popup {
  .scan-package-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 24px;
    padding-top: 8px;
    .scan-package {
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-width: 196px;
      min-height: 177px;
      border: 1px solid rgba(46, 60, 84, 0.1);
      .scan-feature-value {
        font-weight: 500;
        font-size: 24px;
        color: #2E3C54;
      }
      .scan-cost {
        margin: 12px auto 20px auto;
        font-weight: 400;
        font-size: 16px;
        color: #2E3C54;
      }
      .scan-buy {
        display: inline-block;
        padding: 8px 20px;
        background-color: rgba(#EA3553, 0.06);
        border-radius: 27px;
        font-weight: bold;
        font-size: 15px;
        color:#EA3553;
        letter-spacing: 0.08em;
        cursor: pointer;
        transition: .2s;
        &:hover {
          background-color: rgba(#EA3553, 1);
          color: white;
        }
      }
    }
  }
  .scan-package-buy {
    margin: 24px 40px;
  }
}

.new-ebay-template-container {
  .main-popup {
    max-width: fit-content;
  }

  .body {
    align-items: normal;
    padding: 24px 30px;
    .btn-create {
      width: fit-content;
    }
  }
}

.ebay-export {
  .main-popup {
    width: 928px;
    .header {
      background-color: white;
      .close-popup {
        background-color: white;
      }
    }
    .body {
      min-height: 500px;
      padding: 24px 30px;
    }
    .footer {
      .go-back {
        display: flex;
        color: $color-main-red;
        align-items: center;
        svg {
          margin-right: 8px;
        }
        cursor: pointer;
      }
      justify-content: space-between;
      .btn-next {
        align-self: flex-end;
        width: fit-content !important;
        margin: auto 0 0 auto;
      }
    }
  }
  .current-template-container > .fw {
    margin-bottom: 24px;
  }
  .scheduling-container {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
  .react-time-picker {
    margin-top: 6px;
  }
  .react-time-picker input, .react-time-picker select {
    margin-top: 0;
    width: auto;
  }
  .react-time-picker input[type='number'] {
    border: unset !important;
  }
  .stagger-description {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
  }

  .export-listing {
    padding: 48px;
    width: 100%;
    p {
      font-weight: 400;
      font-size: 16px;
    }
    .export-listing-details {
      background: #FFFFFF;
      box-shadow: 0 2px 10px rgba(9, 38, 67, 0.12);
      margin-top: 16px;
      padding: 24px;
      padding-left: 10rem;
    }
  }
}

.match-all-view {
  .main-popup {
    max-width: 814px;
  }
  .body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 9px;
    grid-row-gap: 12px;

  }

  .card-match-popup {
    background: white;
    border: 1px solid rgba(46, 60, 84, 0.1);
    display: flex;
    padding: 12px;
    .card-popup-image {
      img { width: 82px };
    }
    .card-popup-info {
      margin-left: 12px;
      max-width: 220px;
      .year-brand {
        font-family: 'Roboto', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        opacity: 0.7;
        margin-bottom: 10px;
      }
      .card-player {
        opacity: 1;
      }
    }
  }
  .card-match-popup.active {
    border-color: $color-main-red;
    .match-select {
      background-color: #EA3553;
      background-image: url('../images/checkedIcon.svg');
      background-position: center;
      background-size: 16px;
    }
  }
  .match-select {
    width: 22px;
    height: 22px;
    background: white;
    border: 1px solid #EEF0F4;
    border-radius: 100%;
    margin: 0 0 auto auto;
    box-shadow: 0 0 4px rgba(46, 60, 84, .16);
    transition: border .3s, background .3s;
  }
  .apply-changes {
    width: fit-content !important;
    margin: auto;
  }
}
